import { useEffect, useState } from 'react';

export default function useForm(schema) {
  const [isValid, setIsValid] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const change = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const reset = (keepFields = false) => {
    if (!keepFields) setValues(schema.default());
    setErrors({});
    setIsValid(false);
  };

  useEffect(reset, [schema]);

  const validateValues = async (v) => {
    await schema.validate(v, { abortEarly: false }).catch((err) => {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });
    const validation = await schema.isValid(v);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
    return validation;
  };

  const validate = () => {
    validateValues(values);
  };

  return {
    isValid,
    values,
    errors,
    change,
    reset,
    validate,
  };
}
