import { createSlice } from '@reduxjs/toolkit';

import {
  editResellerMsGroupConfigsAsync,
  editResellerMsGroupConfigsExtraReducers,
} from './serviceActions/editResellerMsGroupConfigsAsync';

const initialState = {
  editResellerMsGroupConfigsState: {
    fetching: false,
    statusCode: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
    serviceType: null,
  },
};

const editResellerMsGroupConfigsSlice = createSlice({
  name: 'editResellerMsGroupConfigs',
  initialState,
  reducers: {
    resetEditResellerMsGroupConfigsState(state) {
      state.editResellerMsGroupConfigsState = initialState.editResellerMsGroupConfigsState;
    },
  },
  extraReducers: {
    ...editResellerMsGroupConfigsExtraReducers,
  },
});

export { editResellerMsGroupConfigsAsync };

export const { resetEditResellerMsGroupConfigsState } = editResellerMsGroupConfigsSlice.actions;
export default editResellerMsGroupConfigsSlice.reducer;
