import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { QboButton } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import useFeatureFlag from '@hooks/useFeatureFlag';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import { useDispatch } from 'react-redux';
import { showFixedAlert } from '@features/application/applicationSlice';

const featureLabels = [
  {
    label: 'Message',
    properties: 'message',
  },
  {
    label: 'Drive File',
    properties: 'drive_file',
  },
  {
    label: 'Calendar',
    properties: 'calendar',
  },
  {
    label: 'Contact',
    properties: 'contact',
  },
  {
    label: 'Task',
    properties: 'task',
  },
  {
    label: 'Share Point',
    properties: 'sharepoint',
  },
  {
    label: 'Shared Drive',
    properties: 'shared_drive',
  },
  {
    label: 'Groups & Teams',
    properties: 'ms_group',
  },
  {
    label: 'Private Chat',
    properties: 'ms_chat',
  },
];

const initialEditState = {
  isEditting: false,
  originalValues: {},
  fieldValues: {},
};

export default function ResellerImpersonateFeatureSettingsSection(props) {
  const { data, feature, onSave, disableEdit, disableEditTooltip } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { featureFlags, hasPermission } = useFeatureFlag();
  const [editState, setEditState] = useState(initialEditState);

  const flattenedData = useMemo(() => {
    const result = {};
    featureLabels.forEach((l) => {
      result[l.properties] = data[feature]?.includes(l.properties) ?? false;
    });
    return result;
  }, [data]);

  const changedValues = useMemo(() => {
    if (!editState.originalValues || !editState.fieldValues) {
      return editState.fieldValues ?? {};
    }
    const retval = {};
    Object.keys(editState.originalValues).forEach((k) => {
      if (editState.originalValues[k] === editState.fieldValues[k]) return;
      retval[k] = editState.fieldValues[k] ?? null;
    });
    return retval;
  }, [editState]);

  const isSaveable = changedValues && Object.keys(changedValues).length > 0;

  const handleInitializeEdit = () => {
    setEditState({
      fieldValues: flattenedData,
      originalValues: flattenedData,
      isEditting: true,
    });
  };

  const handleCancelEdit = () => {
    setEditState(initialEditState);
  };

  const handleUpdateField = (fieldName, value) => {
    setEditState({
      ...editState,
      fieldValues: {
        ...editState.fieldValues,
        [fieldName]: value,
      },
    });
  };

  const handleSave = () => {
    if (!isSaveable) {
      dispatch(
        showFixedAlert({
          descriptionHtml: t('partner_list_page.edit_reseller_configs.validations.no_changes'),
          severity: 'error',
        })
      );
      return;
    }
    const preview = [];
    const impersonateSettings = {
      ...data,
      [feature]: Object.keys(editState.fieldValues).filter((k) => editState.fieldValues[k]),
    };

    Object.keys(changedValues).forEach((k) => {
      const label = featureLabels.find((l) => l.properties === k);
      preview.push({
        label: label.label,
        subHeadingText: label.subHeadingText,
        key: k,
        value: changedValues[k],
      });
    });

    onSave({
      preview,
      impersonateSettings,
    });
  };

  return (
    <>
      {hasPermission(featureFlags.EditResellerSettings) && (
        <div className="ResellersPage__settings-toolbar">
          {editState.isEditting ? (
            <>
              <Button
                className="ResellersPage__cancel-button"
                onClick={() => handleCancelEdit('resellerDetails')}>
                Cancel
              </Button>
              <QboButton
                variant="contained"
                disabled={!isSaveable}
                withTitle={!isSaveable}
                title={
                  !isSaveable
                    ? t('partner_list_page.edit_reseller_configs.validations.no_changes')
                    : null
                }
                onClick={handleSave}>
                Save
              </QboButton>
            </>
          ) : (
            <QboButton
              variant="contained"
              onClick={handleInitializeEdit}
              disabled={disableEdit}
              withTitle={disableEdit && disableEditTooltip}
              title={disableEditTooltip}>
              Edit
            </QboButton>
          )}
        </div>
      )}
      {featureLabels?.map((label) => {
        return editState.isEditting ? (
          <QboCardSingleList
            key={label.label}
            subHeadingText={label?.subHeadingText}
            leftContent={label.label}
            editable
            value={editState.fieldValues[label.properties]}
            valueType="bool"
            onChange={(v) => handleUpdateField(label.properties, v)}
            options={label.options}
          />
        ) : (
          <QboCardSingleList
            key={label.label}
            subHeadingText={label?.subHeadingText}
            leftContent={label.label}
            rightContent={flattenedData[label.properties]?.toString()}
          />
        );
      })}
    </>
  );
}

ResellerImpersonateFeatureSettingsSection.propTypes = {
  data: PropTypes.object,
  feature: PropTypes.string,
  onSave: PropTypes.func,
  disableEdit: PropTypes.bool,
  disableEditTooltip: PropTypes.string,
};

ResellerImpersonateFeatureSettingsSection.defaultProps = {
  data: {},
  feature: '',
  onSave: () => {},
  disableEdit: false,
  disableEditTooltip: '',
};
