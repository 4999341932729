import { createSlice } from '@reduxjs/toolkit';
import {
  bulkExcludeMailboxAsync,
  bulkExcludeMailboxExtraReducers,
} from './serviceActions/bulkExcludeMailboxAsync';

const initialState = {
  bulkExcludeMailboxState: {
    fetching: false,
    statusCode: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
    serviceType: null,
  },
};

const bulkExcludeMailboxSlice = createSlice({
  name: 'bulkExcludeMailbox',
  initialState,
  reducers: {
    resetBulkExcludeMailboxState(state) {
      state.bulkExcludeMailboxState = initialState.bulkExcludeMailboxState;
    },
  },
  extraReducers: {
    ...bulkExcludeMailboxExtraReducers,
  },
});

export { bulkExcludeMailboxAsync };

export const { resetBulkExcludeMailboxState } = bulkExcludeMailboxSlice.actions;
export default bulkExcludeMailboxSlice.reducer;
