import { createAsyncThunk } from '@reduxjs/toolkit';
import UserBackupService from '@services/WebApiService/UserBackupService';

const getBulkExcludeMailboxItemsAsync = createAsyncThunk(
  'GetBulkExcludeMailbox/getBulkExcludeMailboxItems',
  async (paramsList) => {
    try {
      const data = await UserBackupService.getBulkExcludeMailboxItemsApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getBulkExcludeMailboxItemsAsync;

const getBulkExcludeMailboxItemsExtraReducers = {
  [pending]: (state) => {
    state.getBulkExcludeMailboxItemsState.fetching = true;
  },
  [rejected]: (state) => {
    state.getBulkExcludeMailboxItemsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getBulkExcludeMailboxItemsState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.getBulkExcludeMailboxItemsState.statusCode = action?.payload?.status;
      state.getBulkExcludeMailboxItemsState.errorMessage = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.getBulkExcludeMailboxItemsState.forbiddenErrorMessage =
        'You are not authorize to access this resource';
    }

    if (!action?.payload?.data && !action.payload?.error) {
      state.getBulkExcludeMailboxItemsState.corsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getBulkExcludeMailboxItemsState.forbiddenState = false;
      state.getBulkExcludeMailboxItemsState.forbiddenErrorMessage = {};
      state.getBulkExcludeMailboxItemsState.statusCode = action.payload?.status;
      state.getBulkExcludeMailboxItemsState.list = action?.payload?.data?.data;

      if (action?.payload?.data?.pagination) {
        state.getBulkExcludeMailboxItemsState.pagination = JSON.parse(
          action?.payload?.data?.pagination
        );
      }
    }
  },
};

export { getBulkExcludeMailboxItemsAsync, getBulkExcludeMailboxItemsExtraReducers };
