import { constant } from '@config/BaseSetting';
import { serviceTypesAsync } from '@features/toolsRequestHistory/serviceTypesSlice';
import { toolsRequestHistoryAsync } from '@features/toolsRequestHistory/toolsRequestHistorySlice';
import useLocalStorage from '@hooks/useLocalStorage';
import useUrlQuery from '@hooks/useUrlQuery';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function generateMatchText(payload, serviceType) {
  let textData = '';
  if (payload) {
    textData = `${
      !textData ? 'Matching' : `${textData}, `
    } Payload/Support ticket/Escalation ticket/Description like '%${payload}%'`;
  }
  if (serviceType) {
    textData = `${!textData ? 'Matching' : `${textData}, `} Service type = ${serviceType}`;
  }
  return textData;
}

const initialPagination = { pageIndex: 0, pageSize: 10 };

export default function useToolsRequestHistory() {
  const dispatch = useDispatch();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const { serviceTypesState } = useSelector((state) => state.serviceTypes);
  const { query, setQuery } = useUrlQuery();
  // const [serviceTypeFragment, setServiceTypeFragment] = useUrlQuery('service-type');

  const [serviceTypeSearch, setServiceTypeSearch] = useState('');
  const [serviceTypeId, setServiceTypeId] = useState();
  const [serviceTypeName, setServiceTypeName] = useState('');
  const [loadedServiceTypes, setLoadedServiceTypes] = useState([]);
  const [shownServiceTypes, setShowServiceTypes] = useState([]);

  const [fetchParams, setFetchParams] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [matchText, setMatchText] = useState('');

  const loadData = () => {
    dispatch(
      toolsRequestHistoryAsync({
        accessToken,
        reset: true,
        payload: fetchParams.payload,
        serviceType: fetchParams.serviceType,
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      })
    );
  };

  useEffect(() => {
    dispatch(serviceTypesAsync());
  }, []);

  useEffect(() => {
    setLoadedServiceTypes(
      serviceTypesState.data.map((e, i) => {
        return {
          id: i,
          value: e['service-type-id'],
          title: e['service-type'],
        };
      })
    );
  }, [serviceTypesState]);

  useEffect(() => {
    const idFromFragment = parseInt(query['service-type'], 10);
    if (!idFromFragment.isNaN) {
      const selectedServiceType = serviceTypesState.data?.find(
        (e) => e['service-type-id'] === idFromFragment
      );
      if (selectedServiceType) {
        setServiceTypeId(selectedServiceType['service-type-id'].toString());
        setServiceTypeName(selectedServiceType['service-type']);
        setMatchText(generateMatchText(searchFieldValue, selectedServiceType['service-type-id']));
        setFetchParams({
          payload: searchFieldValue,
          serviceType: selectedServiceType['service-type-id'],
        });
      }
    }
    setShowServiceTypes(loadedServiceTypes);
  }, [loadedServiceTypes]);

  useEffect(() => {
    setShowServiceTypes(
      loadedServiceTypes.filter((s) =>
        s.title.toLowerCase().includes(serviceTypeSearch.toLowerCase())
      )
    );
  }, [serviceTypeSearch]);

  useEffect(loadData, [fetchParams, pagination]);

  const handleOnSearchFieldChange = (e) => {
    setSearchFieldValue(e.target.value);
  };

  const handleOnSearchClick = (e) => {
    e.preventDefault();
    setMatchText(generateMatchText(searchFieldValue, serviceTypeName));
    setPagination(initialPagination);
    setFetchParams({
      payload: searchFieldValue,
      serviceType: serviceTypeId,
    });
  };

  const handleOnResetClick = () => {
    setMatchText('');
    setSearchFieldValue('');
    setServiceTypeId('');
    setQuery({ ...query, 'service-type': undefined });
    setPagination(initialPagination);
    setFetchParams({});
  };

  const handleOnRefreshClick = loadData;

  const handleOnServiceTypeChanged = (e) => {
    setServiceTypeId(e.target.value);
    setQuery({ ...query, 'service-type': e.target.value });
    setServiceTypeName(loadedServiceTypes.find((s) => s.value === e.target.value)?.title);
  };

  const handleOnServiceTypeSearchChanged = (e) => {
    setServiceTypeSearch(e.target.value);
  };

  const handleOnClearSearchServiceType = () => {
    setServiceTypeSearch('');
  };

  return {
    searchFieldValue,
    matchText,
    pagination,
    serviceTypes: shownServiceTypes,
    serviceTypeId,
    serviceTypeSearch,
    setPagination,
    handleOnSearchFieldChange,
    handleOnSearchClick,
    handleOnResetClick,
    handleOnRefreshClick,
    handleOnServiceTypeChanged,
    handleOnServiceTypeSearchChanged,
    handleOnClearSearchServiceType,
  };
}
