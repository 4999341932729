import { createSlice } from '@reduxjs/toolkit';

import {
  editResellerImpersonateConfigsAsync,
  editResellerImpersonateConfigsExtraReducers,
} from './serviceActions/editResellerImpersonateConfigsAsync';

const initialState = {
  editResellerImpersonateConfigsState: {
    fetching: false,
    statusCode: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
    serviceType: null,
  },
};

const editResellerImpersonateConfigsSlice = createSlice({
  name: 'editResellerImpersonateConfigs',
  initialState,
  reducers: {
    resetEditResellerImpersonateConfigsState(state) {
      state.editResellerImpersonateConfigsState = initialState.editResellerImpersonateConfigsState;
    },
  },
  extraReducers: {
    ...editResellerImpersonateConfigsExtraReducers,
  },
});

export { editResellerImpersonateConfigsAsync };

export const { resetEditResellerImpersonateConfigsState } =
  editResellerImpersonateConfigsSlice.actions;
export default editResellerImpersonateConfigsSlice.reducer;
