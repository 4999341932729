import useUrlQuery from '@hooks/useUrlQuery';

export default function useToolsRequestDetails() {
  const { query, setQuery } = useUrlQuery();

  const handleOnColumnClicked = (r) => {
    setQuery({
      ...query,
      id: r.id,
      'job-type': r['job-type-id'],
    });
  };

  const handleOnModalClosed = () => {
    setQuery({
      ...query,
      id: undefined,
      'job-type': undefined,
    });
  };
  const clickableColumnsList = [{ id: 1, column: 'details', handler: handleOnColumnClicked }];
  return {
    jobId: query.id,
    jobType: query['job-type'],
    clickableColumnsList,
    handleOnModalClosed,
  };
}
