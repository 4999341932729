import {
  ITBox,
  QboAlert,
  QboFormGroup,
  QboLabelGroup,
  QboModal,
  QboSelect,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import useBulkExcludeMailbox from './pageHooks/useBulkExcludeMailbox';

function BulkExcludeMailboxModal({ closeModal, showModal, organization }) {
  const {
    bulkExcludeMailboxState,
    errorMessage,
    form,
    credentialTypeOptions,
    handleSubmit,
    handleClose,
    handleFileUpload,
  } = useBulkExcludeMailbox({
    organization,
    closeModal,
  });

  const { t } = useTranslation();

  return (
    <QboModal
      onClose={handleClose}
      open={showModal}
      title={t('organization_list_page.bulk_exclude_mailbox.title')}
      showCloseIcon
      preventOutsideClick>
      <ITBox direction="TransferSubscriptionFormPage__wrapper column">
        <QboFormGroup
          loadingButton={bulkExcludeMailboxState.fetching}
          onSubmit={handleSubmit}
          withGenerateAndResetButton
          handleOnResetButton={handleClose}
          secondButtonLabel="Cancel"
          firstButtonLabel="Submit">
          {errorMessage && (
            <QboAlert
              variant="outlined"
              type="error"
              style={{
                // border: '1px solid #ebebeb',
                background: '#fdeded',
                fontSize: '10px !important',
              }}>
              <p>
                {t('organization_list_page.bulk_exclude_mailbox.error', { error: errorMessage })}
              </p>
            </QboAlert>
          )}
          <QboAlert
            variant="outlined"
            type="info"
            style={{
              border: '1px solid #ebebeb',
              background: '#f3f3f3',
              fontSize: '10px !important',
              marginTop: '20px',
            }}>
            <QboTypography
              noWrap={false}
              style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
              {t('organization_list_page.bulk_exclude_mailbox.confirmation')}
            </QboTypography>
          </QboAlert>
          <ITBox
            className="TransferSubresellerPage__information-box"
            direction="column"
            sx={{ marginBottom: '20px' }}>
            <QboLabelGroup
              title="Organization Owner ID"
              description={organization?.organizationOwnerId?.toString() || '-'}
            />
            <QboLabelGroup
              title="Organization Name"
              description={organization?.orgName?.toString() || '-'}
            />
          </ITBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboTextField
                id="supportTicket"
                required
                placeholder="Support Ticket"
                label="Support Ticket"
                fullWidth
                disabled={bulkExcludeMailboxState.fetching}
                value={form.values.supportTicket}
                errorMessage={form.errors.supportTicket}
                inputProps={{ maxLength: 225 }}
                onChange={(e) => {
                  form.change('supportTicket', e.currentTarget.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <QboTextField
                id="escalationTicket"
                placeholder="Escalation Ticket"
                label="Escalation Ticket"
                fullWidth
                disabled={bulkExcludeMailboxState.fetching}
                value={form.values.escalationTicket}
                onChange={(e) => {
                  form.change('escalationTicket', e.currentTarget.value);
                }}
                inputProps={{ maxLength: 225 }}
              />
            </Grid>
          </Grid>
          <QboTextField
            id="description"
            placeholder="Description"
            label="Description"
            fullWidth
            disabled={bulkExcludeMailboxState.fetching}
            value={form.values.description}
            onChange={(e) => {
              form.change('description', e.currentTarget.value);
            }}
            inputProps={{ maxLength: 225 }}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboTextField
                id="credentialId"
                required
                placeholder="Credential ID"
                label="Credential ID"
                fullWidth
                disabled={bulkExcludeMailboxState.fetching}
                value={form.values.credentialId}
                errorMessage={form.errors.credentialId}
                inputProps={{ maxLength: 225 }}
                onChange={(e) => {
                  form.change('credentialId', e.currentTarget.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <QboSelect
                label="Select credential type"
                options={credentialTypeOptions}
                handleChange={(e) => {
                  form.change('credentialType', e.target.value);
                }}
                disabled={bulkExcludeMailboxState.fetching}
                value={form.values.credentialType}
                errorMessage={form.errors.credentialType}
                defaultValue="1"
              />
            </Grid>
          </Grid>

          <QboTextField
            id="emails"
            required
            placeholder="Emails"
            label="Emails"
            multiline
            rows={5}
            fullWidth
            disabled={bulkExcludeMailboxState.fetching}
            value={form.values.emails}
            errorMessage={form.errors.emails}
            onChange={(e) => {
              form.change('emails', e.currentTarget.value);
            }}
          />
          <QboTextField
            id="csvFile"
            placeholder="CSV File"
            label="CSV File"
            type="file"
            fullWidth
            disabled={bulkExcludeMailboxState.fetching}
            inputProps={{ maxLength: 225 }}
            onChange={handleFileUpload}
          />
        </QboFormGroup>
      </ITBox>
    </QboModal>
  );
}

BulkExcludeMailboxModal.propTypes = {
  closeModal: PropTypes.func,
  showModal: PropTypes.bool,
  organization: PropTypes.object,
};

BulkExcludeMailboxModal.defaultProps = {
  closeModal: () => {},
  showModal: false,
  organization: null,
};

export default BulkExcludeMailboxModal;
