import { createSlice } from '@reduxjs/toolkit';

import {
  getSubscriptionHistoryListAsync,
  getSubscriptionHistoryListExtraReducers,
} from './serviceActions/getSubscriptionHistoryListAsync';

const initialState = {
  getSubscriptionHistoryListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    subscriptionHistoryList: [],
  },
};

const subscriptionHistoryListSlice = createSlice({
  name: 'SubscriptionHistoryList',
  initialState,
  reducers: {
    resetSubscriptionHistoryListState(state) {
      state.getSubscriptionHistoryListState = initialState.getSubscriptionHistoryListState;
    },
  },
  extraReducers: {
    ...getSubscriptionHistoryListExtraReducers,
  },
});

export { getSubscriptionHistoryListAsync };
export const { resetSubscriptionHistoryListState, resetSubscriptionHistoryListStatusCodeState } =
  subscriptionHistoryListSlice.actions;
export default subscriptionHistoryListSlice.reducer;
