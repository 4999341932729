import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  QboSimpleDataTable,
  QboCard,
  QboAlert,
  QboPagination,
  QboCardListItem,
  QboFormGroup,
  QboModal,
  QboTypography,
  QboBreadcrumbs,
  QboLink,
} from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import QboSortByButton from '@ui/Components/QboSortByButton';
import { t } from 'i18next';
import {
  resetPartnerFilterState,
  resetOrganizationFilterState,
} from '@features/application/applicationSlice';
import useUrlQuery from '@hooks/useUrlQuery';
import { useNavigate } from 'react-router-dom';
import { sorMonitoringPath } from '@config/Routes/WebClientRoutes';
import SimpleTableModalSection from '@ui/Sections/Shared/SimpleTableModalSection';
import useSORMonitoringDetailFetching from './pageHooks/useSORMonitoringDetailFetching';
import {
  systemStatusItems,
  entityTypeItems,
  entityRoleItems,
  booleanItems,
} from './pageHooks/constants/sorMonitoringDetailConstants';

export default function SORMonitoringDetailPage() {
  const navigate = useNavigate();
  const { query } = useUrlQuery();
  const dispatch = useDispatch();
  const { sorMonitoringDetailState, sorMonitoringResponseState } = useSelector(
    (state) => state.sorMonitoring
  );
  const {
    fetching,
    sorMonitoringDetail,
    paginations,
    forbiddenErrorMessage,
    sorMonitoringDetailCorsErrorMessage,
  } = sorMonitoringDetailState;

  const { fetching: loadData, sorMonitoringResponse } = sorMonitoringResponseState;

  // fetching
  const defaultPagination = { pageNumber: 1, pageSize: 10 };
  const [paginationState, setPaginationState] = useState(defaultPagination);

  // filter
  const [onSearch, setOnSearch] = useState(false);
  const defaultFilter = {
    status: '0',
    entityType: '',
    entityRole: '',
    hasRetry: '',
  };
  const [filterState, setFilterState] = useState(defaultFilter);

  const {
    handleFetchSORMonitoringDetailAsync,
    tableColumns,
    showMesageDialog,
    setShowMesageDialog,
    messageDialog,
    auditTrailsDetailId,
    handleOnModalClosed,
    setMesageDialog,
  } = useSORMonitoringDetailFetching(
    defaultFilter,
    setFilterState,
    defaultPagination,
    setPaginationState
  );

  const fetchHandler = () => {
    setOnSearch(true);
    if (!fetching && query.id) {
      handleFetchSORMonitoringDetailAsync(
        paginationState.pageNumber,
        paginationState.pageSize,
        query.id,
        filterState
      );
      setOnSearch(false);
    }
  };

  const handleChangePage = (newPage) => {
    setPaginationState({ pageNumber: newPage, pageSize: paginationState.pageSize });
  };

  const handleChangePageSize = (newPageSize) => {
    setPaginationState({ pageNumber: 1, pageSize: newPageSize });
  };

  const handleFilterdChange = (value, key) => {
    const newFilter = { ...filterState, [key]: value };
    setFilterState(newFilter);
  };

  const handleOnSearchClick = async (e) => {
    e.preventDefault();
    setPaginationState(defaultPagination);
    setOnSearch(true);
  };

  const handleOnResetButtonClick = (e) => {
    setPaginationState(defaultPagination);
    setFilterState(defaultFilter);
    setOnSearch(true);
    dispatch(resetPartnerFilterState());
    dispatch(resetOrganizationFilterState());
  };

  useEffect(() => {
    if (onSearch) {
      fetchHandler();
    }
  }, [onSearch]);

  useEffect(() => {
    fetchHandler();
  }, [paginationState.pageNumber, paginationState.pageSize]);

  return (
    <>
      <HeaderPageBlock>Audit Trails Details</HeaderPageBlock>
      <QboBreadcrumbs sx={{ marginBottom: 2 }}>
        <QboLink to={sorMonitoringPath}>System Of Record Monitoring</QboLink>
        <QboTypography color="text.primary">Audit Trails Details (ID: {query.id})</QboTypography>
      </QboBreadcrumbs>
      {sorMonitoringDetailCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {sorMonitoringDetailCorsErrorMessage}
        </QboAlert>
      )}
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate">
          <div>
            <QboSortByButton
              defaultValue="All"
              marginRightValue={2}
              value={filterState.status}
              buttonWidth={220}
              startAdornmentTextWidth={70}
              startAdornmentText="Status"
              handleChange={(e) => {
                handleFilterdChange(e.target.value, 'status');
              }}
              menuItemList={systemStatusItems}
            />
            <QboSortByButton
              defaultValue="All"
              marginRightValue={2}
              value={filterState.entityType}
              buttonWidth={220}
              startAdornmentTextWidth={70}
              startAdornmentText="Type"
              handleChange={(e) => {
                handleFilterdChange(e.target.value, 'entityType');
              }}
              menuItemList={entityTypeItems}
            />
            <QboSortByButton
              defaultValue="All"
              marginRightValue={2}
              value={filterState.entityRole}
              buttonWidth={220}
              startAdornmentTextWidth={70}
              startAdornmentText="Role"
              handleChange={(e) => {
                handleFilterdChange(e.target.value, 'entityRole');
              }}
              menuItemList={entityRoleItems}
            />
            <QboSortByButton
              defaultValue="All"
              marginRightValue={2}
              value={filterState.hasRetry}
              buttonWidth={220}
              startAdornmentTextWidth={120}
              startAdornmentText="Has Retry"
              handleChange={(e) => {
                handleFilterdChange(e.target.value, 'hasRetry');
              }}
              menuItemList={booleanItems}
            />
          </div>
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <>
          {(sorMonitoringDetail?.length || fetching) && (
            <QboSimpleDataTable
              sx={{ width: '100%' }}
              rows={sorMonitoringDetail}
              header={tableColumns()}
              loading={fetching}
            />
          )}
          {(sorMonitoringDetail?.length || fetching) && (
            <QboCardListItem
              justifyContent="right"
              alignItems="center"
              style={{ paddingRight: '1.5em' }}
              rightContent={
                <QboPagination
                  hasNextLink={paginations?.HasNext}
                  hasPrevLink={paginations?.HasPrevious}
                  onClickPrevLink={() => {
                    const newPageNumber = paginationState.pageNumber - 1;
                    handleChangePage(newPageNumber);
                  }}
                  onClickNextLink={() => {
                    const newPageNumber = paginationState.pageNumber + 1;
                    handleChangePage(newPageNumber);
                  }}
                  onChangePageNumber={handleChangePageSize}
                  pageNumber={paginationState.pageSize}
                  paginationLabel="Page size"
                />
              }
            />
          )}

          {!sorMonitoringDetail?.length && !fetching && (
            <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
          )}

          <QboModal
            classNameStyle="Qbo_model_error_message"
            title={messageDialog.title}
            showCloseIcon
            open={showMesageDialog}
            onClose={() => setShowMesageDialog(false)}>
            <QboTypography wrap={!false}>{messageDialog.message}</QboTypography>
          </QboModal>

          {!!auditTrailsDetailId && (
            <SimpleTableModalSection
              id={auditTrailsDetailId}
              fetching={loadData}
              columns={[
                { label: 'Response ID', columnName: 'id' },
                { label: 'Retry Number', columnName: 'retryId' },
                {
                  label: 'Payload',
                  columnName: 'payload',
                  onClickCell: (row) => {
                    setShowMesageDialog(true);
                    setMesageDialog({ title: 'Payload', message: row.payload });
                    return '';
                  },
                },
                {
                  label: 'Message',
                  columnName: 'message',
                  onClickCell: (row) => {
                    setShowMesageDialog(true);
                    setMesageDialog({ title: 'Message', message: row.message });
                    return '';
                  },
                },
                { label: 'Response Time', columnName: 'responseTime' },
                { label: 'Status', columnName: 'status' },
              ]}
              title={`Audtrails Response List (Detail ID: ${auditTrailsDetailId})`}
              dataState={sorMonitoringResponse}
              handleOnClose={handleOnModalClosed}
            />
          )}
        </>
      </QboCard>
    </>
  );
}
