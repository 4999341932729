import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  organizationInformationPath,
  showOrganizationListPath,
  showPartnerListPath,
} from '@config/Routes/WebClientRoutes';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { QboBreadcrumbs, QboCard, QboTabs, QboTypography } from '@ui/Components';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  setOrganizationDetailState,
  setOrganizationTabState,
  setTabState,
} from '@features/application/applicationSlice';
import OrganizationInformationPage from '@pages/OrganizationInformationPage';
import M365TenantPage from '@pages/TenantPage/M365Tenant';
import GWSTenantPage from '@pages/TenantPage/GWSTenant';
import ManageLogPage from '@pages/ManageLogPage';
import { organizationLogType } from '@pages/ManageLogPage/pageHooks/useManageLog';
import UserBackupPage from '@pages/UserBackupPage';
import HEXTenantPage from '@pages/TenantPage/HEXTenant';
import OrganizationUserListPage from '@pages/OrganizationUsersListPage';
import BackupStatusPage from '@pages/BackupStatusPage';
import EntraIdOrganizationPage from '@pages/EntraIdOrganizationPage';
import EntraIdTenantPage from '@pages/TenantPage/EntraIdTenant';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useUrlQuery from '@hooks/useUrlQuery';
import SubscripitionHistoryPage from '@pages/subscriptionHistoryPage';
import MigrateStatusPage from '@pages/MigrateStatusPage';

export default function OrganizationNamesTab() {
  const { featureFlags, hasPermission } = useFeatureFlag();
  const { organizationDetailState } = useSelector((state) => state.application);
  const { selectedTabState } = useSelector((state) => state.application);
  const [selectTab, setSelectTab] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [original, setOriginal] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const previousM365OrganizationID = useRef(null);
  const previousGWSOrganizationID = useRef(null);
  const previousHEXOrganizationID = useRef(null);
  const paginationProp = useRef();
  const gwsPaginationProp = useRef();
  const hexPaginationProp = useRef();
  const names = [
    'Details and Configs',
    'M365 Tenant',
    'GWS Tenant',
    'HEX Tenant',
    ...(hasPermission(featureFlags.EntraId) ? ['Entra Tenant', 'Entra'] : []),
    'Logs',
    'Backups',
    'Users',
    'Backup Status',
    'Migrate Status',
    'Subscription History',
  ];
  const { row } = organizationDetailState;

  useEffect(() => {
    setLoaded(false);
  }, [row, location]);

  useEffect(() => {
    if (loaded) return;
    const paramsString = location.search.startsWith('?')
      ? location.search.substring(1)
      : location.search;
    const searchParams = new URLSearchParams(paramsString);
    const rowObj = row?.row?.original || row;
    setOriginal({
      ...rowObj,
      organizationOwnerId: searchParams.get('id') ?? rowObj.id,
      orgName: searchParams.get('name') ?? rowObj.name,
    });
    setLoaded(true);
  }, [loaded]);

  useEffect(() => {
    if (loaded && (!original?.organizationOwnerId || !original?.orgName)) {
      navigate(showOrganizationListPath);
    }
  }, [loaded, original]);

  useEffect(() => {
    if (selectedTabState?.tabState === 0) {
      setSelectTab(selectedTabState?.tabState);
    }
  }, [selectedTabState?.tabState, selectTab]);

  const handleOnBreadcrumbClick = () => {
    dispatch(setOrganizationDetailState({}));
    dispatch(setOrganizationTabState(null));
  };
  const handleOnTabClick = (e, newValue) => {
    setSelectTab(newValue);
    dispatch(setTabState(newValue));
  };

  const yourComponent = (namesArray, selectTabState) => {
    if (namesArray[selectTabState] === 'Details and Configs') {
      return <OrganizationInformationPage resellerID={original?.organizationOwnerId} />;
    }
    if (namesArray[selectTabState] === 'M365 Tenant') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <M365TenantPage
            organizationOwnerId={original?.organizationOwnerId}
            organizationOwnerIdProp={previousM365OrganizationID}
            paginationProp={paginationProp}
          />
        </QboCard>
      );
    }
    if (namesArray[selectTabState] === 'GWS Tenant') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <GWSTenantPage
            organizationOwnerId={original?.organizationOwnerId}
            organizationOwnerIdProp={previousGWSOrganizationID}
            paginationProp={gwsPaginationProp}
          />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'HEX Tenant') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <HEXTenantPage
            organizationOwnerId={original?.organizationOwnerId}
            organizationOwnerIdProp={previousHEXOrganizationID}
            paginationProp={hexPaginationProp}
          />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Entra Tenant') {
      return <EntraIdTenantPage organizationOwnerId={original?.organizationOwnerId} />;
    }

    if (namesArray[selectTabState] === 'Entra') {
      return <EntraIdOrganizationPage organizationOwnerId={original?.organizationOwnerId} />;
    }

    if (namesArray[selectTabState] === 'Logs') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <ManageLogPage
            type={organizationLogType}
            id={original?.id}
            orgOwnerId={original?.organizationOwnerId}
          />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Backups') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <UserBackupPage
            resellerId={original?.resellerId}
            organizationOwnerId={original?.organizationOwnerId}
          />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Users') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <OrganizationUserListPage
            orgOwnerId={original?.organizationOwnerId?.toString()}
            tabFlag={namesArray[selectTabState]}
            selectTab={selectTabState}
          />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Backup Status') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <BackupStatusPage
            resellerId={original?.resellerId}
            organizationOwnerId={original?.organizationOwnerId}
          />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Migrate Status') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <MigrateStatusPage
            resellerId={original?.resellerId}
            organizationOwnerId={original?.organizationOwnerId}
          />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Subscription History') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <SubscripitionHistoryPage organizationOwnerId={original?.organizationOwnerId} />
        </QboCard>
      );
    }

    return (
      <QboCard sx={{ marginTop: 2 }} isCentered isBordered>
        <HeaderPageBlock>
          The {namesArray[selectTabState] || namesArray[0]} feature will be available soon.
        </HeaderPageBlock>
      </QboCard>
    );
  };
  return (
    <>
      <HeaderPageBlock>Organization Information</HeaderPageBlock>
      <QboBreadcrumbs sx={{ marginBottom: 2 }}>
        <Link
          onClick={() => handleOnBreadcrumbClick()}
          to={showOrganizationListPath}
          style={{
            color: '#1c63d5',
            fontSize: '1.5rem',
            textDecoration: 'underline',
          }}>
          Organizations
        </Link>
        <QboTypography color="text.primary" style={{ fontSize: '1.5rem' }}>
          Organizations Information
        </QboTypography>
      </QboBreadcrumbs>

      {loaded && original && (
        <QboCard isMainWrapper>
          {original?.orgName && (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
              <QboTypography variant="h5" wrap sx={{ fontSize: 18 }}>
                {original?.orgName}
              </QboTypography>
              <QboTypography variant="h5" sx={{ marginLeft: 1, fontSize: 16 }}>
                (ID: {original?.organizationOwnerId})
              </QboTypography>
            </div>
          )}
          <div
            style={{
              overflowX: 'auto',
              marginRight: 5,
              display: 'flex',
              scrollbarWidth: 'thin',
              backgroundColor: '#c4e3f0',
            }}>
            <QboTabs tabIndex={selectTab} title={names} onChange={handleOnTabClick} />
          </div>
          {yourComponent(names, selectTab, original)}
        </QboCard>
      )}
    </>
  );
}
