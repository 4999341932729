import useUrlQuery from '@hooks/useUrlQuery';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  QboAlert,
  QboCard,
  QboCardListItem,
  QboFormGroup,
  QboModal,
  QboPagination,
  QboSimpleDataTable,
  QboTypography,
} from '@ui/Components';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useDateTime from '@hooks/useDateTime';
import useFeatureFlag from '@hooks/useFeatureFlag';
import {
  resetOrganizationFilterState,
  resetPartnerFilterState,
} from '@features/application/applicationSlice';
import QboSortByButton from '@ui/Components/QboSortByButton';
import { useTranslation } from 'react-i18next';
import PartnerFilterSection from '@ui/Sections/Shared/PartnerFilterSection';
import OrganizationFilterSection from '@ui/Sections/Shared/OrganizationFilterSection';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { buttonStyle, systemStatusItems } from './pageHooks/constants/migrateStatusConstants';
import useMigrateStatusFetching from './pageHooks/useMigrateStatusFetching';

const hasTimeZoneRegex = /^.*(Z|[+-]\d{2}:\d{2})$/;

function getDefaultFilters({ query, resellerId, organizationOwnerId }) {
  let date = dayjs(query.period);
  if (!query.period || !date.isValid()) date = dayjs();

  const filters = {
    status: systemStatusItems.find((i) => i.value === query.status)?.value || '',
    startDate: date.startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    endDate: date.endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    date,
    selectedPartnerId: resellerId,
    selectedOrganizationOwnerId: organizationOwnerId,
    selectedParentId: null,
  };

  return filters;
}

export default function MigrateStatusPage(props) {
  const { t } = useTranslation();
  const { query, setQuery } = useUrlQuery();
  const { dateDistance, dateTimeNow } = useDateTime();
  const { resellerId, organizationOwnerId } = props;
  const dispatch = useDispatch();
  const { migrateStatusListState } = useSelector((state) => state.migrateStatusState);
  const {
    error,
    statusCode,
    fetching,
    migrateStatusList,
    paginations,
    forbiddenErrorMessage,
    migrateStatusListCorsErrorMessage,
  } = migrateStatusListState;

  // Elapsed time
  const getElapsedTime = (item) => {
    if (!item.startTime) return '-';
    const start = hasTimeZoneRegex.test(item.startTime) ? item.startTime : `${item.startTime}Z`;
    const status = item.status?.toLowerCase();
    if (status === 'completed' || status === 'error') {
      if (!item.endTime) return '-';
      const end = hasTimeZoneRegex.test(item.endTime) ? item.endTime : `${item.endTime}Z`;
      return dateDistance(start, end, { separator: ' ' }) || '-';
    }
    return dateDistance(start, dateTimeNow(), { separator: ' ' }) || '-';
  };

  // Add calculated values to the list
  const calcList = useMemo(
    () =>
      migrateStatusList?.map((item) => {
        return {
          ...item,
          elapsedTime: getElapsedTime(item),
        };
      }),
    [migrateStatusList]
  );

  // pagination
  const defaultPagination = { pageNumber: 1, pageSize: 10 };
  const [paginationState, setPaginationState] = useState(defaultPagination);

  // filter
  const [onSearch, setOnSearch] = useState(false);
  const defaultFilter = useMemo(
    () => getDefaultFilters({ query, resellerId, organizationOwnerId }),
    [query, resellerId, organizationOwnerId]
  );
  const [filterState, setFilterState] = useState(defaultFilter);
  const resetFilters = useCallback(
    (q) => {
      setQuery(q);
      setFilterState(getDefaultFilters({ query: q, resellerId, organizationOwnerId }));
    },
    [resellerId, organizationOwnerId]
  );

  const {
    handleFetchMigrateStatusListAsync,
    tableColumns,
    showMesageDialog,
    setShowMesageDialog,
    messageDialog,
  } = useMigrateStatusFetching(
    defaultFilter,
    setFilterState,
    defaultPagination,
    setPaginationState
  );

  // handlers
  const fetchHandler = () => {
    setOnSearch(true);
    if (!fetching) {
      handleFetchMigrateStatusListAsync(
        paginationState.pageNumber,
        paginationState.pageSize,
        filterState.selectedPartnerId,
        filterState.selectedOrganizationOwnerId,
        filterState
      );
      setOnSearch(false);
    }
  };

  const handleChangePage = (newPage) => {
    setPaginationState({ pageNumber: newPage, pageSize: paginationState.pageSize });
  };

  const handleChangePageSize = (newPageSize) => {
    setPaginationState({ pageNumber: 1, pageSize: newPageSize });
  };

  const handleFilterdChange = (value, key) => {
    const newFilter = { ...filterState, [key]: value };
    setFilterState(newFilter);
  };

  const handleOnSearchClick = async (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      period: filterState.date.format('YYYY-MM'),
      status: filterState.status,
    });
    setPaginationState(defaultPagination);
    setOnSearch(true);
  };

  const handleOnResetButtonClick = (e) => {
    resetFilters({
      ...query,
      period: undefined,
      status: undefined,
    });
    setPaginationState(defaultPagination);
    setOnSearch(true);
    dispatch(resetPartnerFilterState());
    dispatch(resetOrganizationFilterState());
  };

  const handleOnChangeDate = (newDate) => {
    const date = newDate;
    const startDate = newDate.startOf('month').format('YYYY-MM-DDTHH:mm:ss');
    const endDate = newDate.endOf('month').format('YYYY-MM-DDTHH:mm:ss');

    const newFilter = { ...filterState, date, startDate, endDate };
    setFilterState(newFilter);
  };

  useEffect(() => {
    if (onSearch) {
      fetchHandler();
    }
  }, [onSearch]);

  useEffect(() => {
    fetchHandler();
  }, [paginationState.pageNumber, paginationState.pageSize]);

  return (
    <>
      <HeaderPageBlock>Migrate Status</HeaderPageBlock>
      {migrateStatusListCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {migrateStatusListCorsErrorMessage}
        </QboAlert>
      )}
      <QboCard isMainWrapper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Period"
            views={['year', 'month']}
            value={filterState.date}
            defaultValue={dayjs()}
            maxDate={dayjs()}
            minDate={dayjs().subtract(2, 'year')}
            onChange={handleOnChangeDate}
            sx={{ marginBottom: 2, marginRight: 2 }}
          />
        </LocalizationProvider>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <div>
            <QboSortByButton
              defaultValue="All"
              marginRightValue={2}
              value={filterState.status}
              buttonWidth={220}
              startAdornmentTextWidth={70}
              startAdornmentText="Status"
              handleChange={(e) => {
                handleFilterdChange(e.target.value, 'status');
              }}
              menuItemList={systemStatusItems}
            />
            {!resellerId && !organizationOwnerId && (
              <PartnerFilterSection
                parentId={filterState.selectedParentId}
                label="Partners"
                inputSize={180}
                handleChange={(value) => {
                  handleFilterdChange(parseInt(value, 10), 'selectedPartnerId');
                }}
              />
            )}
            {!organizationOwnerId && (
              <OrganizationFilterSection
                partnerId={filterState.selectedPartnerId}
                label="Organizations"
                inputSize={180}
                handleChange={(value) => {
                  handleFilterdChange(value, 'selectedOrganizationOwnerId');
                }}
              />
            )}
          </div>
        </QboFormGroup>
      </QboCard>
      <QboCard noPadding isMainWrapper>
        <>
          {(calcList?.length || fetching) && (
            <QboSimpleDataTable
              sx={{ width: '100%' }}
              rows={calcList}
              header={tableColumns()}
              loading={fetching}
            />
          )}
          {(calcList?.length || fetching) && (
            <QboCardListItem
              justifyContent="right"
              alignItems="center"
              style={{ paddingRight: '1.5em' }}
              rightContent={
                <QboPagination
                  hasNextLink={paginations?.HasNext}
                  hasPrevLink={paginations?.HasPrevious}
                  onClickPrevLink={() => {
                    const newPageNumber = paginationState.pageNumber - 1;
                    handleChangePage(newPageNumber);
                  }}
                  onClickNextLink={() => {
                    const newPageNumber = paginationState.pageNumber + 1;
                    handleChangePage(newPageNumber);
                  }}
                  onChangePageNumber={handleChangePageSize}
                  pageNumber={paginationState.pageSize}
                  paginationLabel="Page size"
                  paginationPages={[]}
                />
              }
            />
          )}

          {!calcList?.length &&
            !fetching &&
            (statusCode === 500 ? (
              <NoStatusSection forbiddenErrorMessage={{ heading: t('error'), caption: error }} />
            ) : (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            ))}

          <QboModal
            classNameStyle="Qbo_model_error_message"
            title={messageDialog.title}
            showCloseIcon
            open={showMesageDialog}
            onClose={() => setShowMesageDialog(false)}>
            <QboTypography wrap={!false}>{messageDialog.message}</QboTypography>
          </QboModal>
        </>
      </QboCard>
    </>
  );
}

MigrateStatusPage.propTypes = {
  resellerId: PropTypes.number,
  organizationOwnerId: PropTypes.number,
};

MigrateStatusPage.defaultProps = {
  resellerId: null,
  organizationOwnerId: null,
};
