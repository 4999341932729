import { editResellerMsGroupConfigsAPI } from '@services/WebApiService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const editResellerMsGroupConfigsAsync = createAsyncThunk(
  'EditResellerMsGroupConfigs/editResellerMsGroupConfigs',
  async (paramsList) => {
    try {
      const data = await editResellerMsGroupConfigsAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = editResellerMsGroupConfigsAsync;

const editResellerMsGroupConfigsExtraReducers = {
  [pending]: (state) => {
    state.editResellerMsGroupConfigsState.fetching = true;
  },
  [rejected]: (state) => {
    state.editResellerMsGroupConfigsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.editResellerMsGroupConfigsState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.editResellerMsGroupConfigsState.statusCode = action?.payload?.status;
      state.editResellerMsGroupConfigsState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.editResellerMsGroupConfigsState.message =
        'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.editResellerMsGroupConfigsState.message =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.editResellerMsGroupConfigsState.statusCode = action?.payload?.error?.status;
      state.editResellerMsGroupConfigsState.message =
        statusMessage?.title || statusMessage?.message;
      state.editResellerMsGroupConfigsState.error = action.payload.error;
    } else {
      state.editResellerMsGroupConfigsState.success = true;
      state.editResellerMsGroupConfigsState.serviceType = action.payload.data.data.serviceTypeCode;
    }
  },
};

export { editResellerMsGroupConfigsAsync, editResellerMsGroupConfigsExtraReducers };
