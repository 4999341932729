import { organizationInformationPath, resellersListPath } from '@config/Routes/WebClientRoutes';

export const MS_CHAT_STATUS = 'ms_group';
export const MS_CHAT_STATUS_COLUMNS = [
  { label: 'ID', columnName: 'backupId' },
  { label: 'Email Account ID', columnName: 'userBackupId' },
  {
    label: 'Email',
    columnName: 'email',
    size: 'sm',
  },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
    navigateURL: (row) =>
      `${organizationInformationPath}?id=${row.organizationOwnerId}&name=${row.organizationName}`,
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
    navigateURL: (row) => `${resellersListPath}?id=${row.partnerId}&name=${row.partnerName}`,
  },
  {
    label: 'Parent Name',
    columnName: 'parentName',
    size: 'sm',
  },
  { label: 'Backup Status', columnName: 'status', size: 'sm' },
  { label: 'Start Backup Time', columnName: 'startTime', size: 'sm' },
  { label: 'End Backup Time', columnName: 'endTime', size: 'sm' },
  { label: 'Backup Storage KB', columnName: 'backupStorage', size: 'sm' },
  { label: 'Current Chat Numbers', columnName: 'currentMessagesCount', size: 'sm' },
  { label: 'Total Chat Numbers', columnName: 'totalMessagesCount', size: 'sm' },
  { label: 'Retries Time', columnName: 'retries', size: 'sm' },
  { label: 'Error Messages', columnName: 'errorMessage', size: 'sm' },
  { label: 'Run on Machine IP', columnName: 'machineIP', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
];
