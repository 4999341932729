import { constant } from '@config/BaseSetting';
import {
  dashboardPath,
  transferSubresellerPagePath,
  transferSubscriptionPagePath,
  mailBoxChangePath,
  mailBoxSyncPath,
  viewExcludedMailBoxPath,
  viewAutoDiscoveryPath,
  dataIngestionPath,
  dataIngestionListPath,
  bulkPurgePath,
  bulkPurgeListPath,
  domainBackupsPath,
  transferPath,
  seatUsageReportsPagePath,
  purgeExternalAccountPath,
  purgeSharedDrivePath,
  m365TenantListPath,
  gwsTenantListPath,
  showPartnerListPath,
  showOrganizationListPath,
  showUsersListPath,
  monthlyBackupReportsPagePath,
  seatCountChangePath,
  viewTenantAuthErrorsPath,
  resellersListPath,
  organizationInformationPath,
  showPlansListPath,
  switchSPATypePath,
  recreateBackupAdminPath,
  purgeM365TenantPath,
  m365TenantDetailPath,
  userBackupInfoPath,
  userBackupDetailPath,
  gwsTenantDetailPath,
  hexTenantListPath,
  hexTenantDetailPath,
  backupStatusesPath,
  downloadStatusesPath,
  restoreStatusesPath,
  migrateStatusesPath,
} from '@config/Routes/WebClientRoutes';
import { useLocalStorage } from '@hooks';

export default function usePageMenu() {
  const [activeMenu, setActivePageMenu] = useLocalStorage(constant.MENU_STORAGE);
  const isActiveMenu = (menu) => menu === activeMenu;
  switch (window.location.pathname) {
    case dashboardPath:
      setActivePageMenu(constant.MENUS.HOME_MENU);
      break;
    case showPartnerListPath:
      setActivePageMenu(constant.MENUS.PARTNER_MENU);
      break;
    case showOrganizationListPath:
      setActivePageMenu(constant.MENUS.ORGANIZATION_MENU);
      break;
    case showUsersListPath:
      setActivePageMenu(constant.MENUS.USERS_MENU);
      break;
    case monthlyBackupReportsPagePath:
      setActivePageMenu(constant.MENUS.MONTHLY_BACKUP_REPORT_MENU);
      break;
    case seatCountChangePath:
      setActivePageMenu(constant.MENUS.SEAT_COUNT_CHANGE_MENU);
      break;
    case viewTenantAuthErrorsPath:
      setActivePageMenu(constant.MENUS.CHECK_AUTH_ERROR_MENU);
      break;
    case resellersListPath:
      setActivePageMenu(constant.MENUS.PARTNER_MENU);
      break;
    case organizationInformationPath:
      setActivePageMenu(constant.MENUS.ORGANIZATION_MENU);
      break;
    case showPlansListPath:
      setActivePageMenu(constant.MENUS.PLANS_MENU);
      break;
    case switchSPATypePath:
      setActivePageMenu(constant.MENUS.SWITCH_SPA_TYPE_MENU);
      break;
    case recreateBackupAdminPath:
      setActivePageMenu(constant.MENUS.RECREATE_BCKUP_ADMIN_MENU);
      break;
    case transferSubresellerPagePath:
      setActivePageMenu(constant.MENUS.PARTNER_TRANSFER_MENU);
      break;
    case transferSubscriptionPagePath:
      setActivePageMenu(constant.MENUS.TRANSFER_SUBSCRIPTION_MENU);
      break;
    case mailBoxChangePath:
      setActivePageMenu(constant.MENUS.MAILBOX_CHANGE);
      break;
    case mailBoxSyncPath:
      setActivePageMenu(constant.MENUS.MAILBOX_SYNC);
      break;
    case viewExcludedMailBoxPath:
      setActivePageMenu(constant.MENUS.VIEW_EXCLUDED_MAILBOX);
      break;
    case viewAutoDiscoveryPath:
      setActivePageMenu(constant.MENUS.VIEW_AUTO_DISCOVERY);
      break;
    case dataIngestionPath:
      setActivePageMenu(constant.MENUS.DATA_INGESTION);
      break;
    case dataIngestionListPath:
      setActivePageMenu(constant.MENUS.DATA_INGESTION);
      break;
    case bulkPurgePath:
      setActivePageMenu(constant.MENUS.MAILBOX_PURGE);
      break;
    case bulkPurgeListPath:
      setActivePageMenu(constant.MENUS.MAILBOX_PURGE);
      break;
    case domainBackupsPath:
      setActivePageMenu(constant.MENUS.DOMAIN_BACKUPS);
      break;
    case transferPath:
      setActivePageMenu(constant.MENUS.TRANSFER);
      break;
    case purgeExternalAccountPath:
      setActivePageMenu(constant.MENUS.EXTERNAL_ACCOUNT);
      break;
    case purgeSharedDrivePath:
      setActivePageMenu(constant.MENUS.SHARED_DRIVE);
      break;
    case seatUsageReportsPagePath:
      setActivePageMenu(constant.MENUS.SEAT_USAGE);
      break;
    case m365TenantListPath:
      setActivePageMenu(constant.MENUS.TENANT_M365_INFO);
      break;
    case gwsTenantListPath:
      setActivePageMenu(constant.MENUS.TENANT_GWS_INFO);
      break;
    case purgeM365TenantPath:
      setActivePageMenu(constant.MENUS.PURGE_M365_TENANT);
      break;
    case m365TenantDetailPath:
      setActivePageMenu(constant.MENUS.M365_TENANT_DETAILS);
      break;
    case userBackupInfoPath:
      setActivePageMenu(constant.MENUS.USERBACKUP_MENU);
      break;
    case userBackupDetailPath:
      setActivePageMenu(constant.MENUS.USERBACKUP_MENU);
      break;
    case backupStatusesPath:
      setActivePageMenu(constant.MENUS.BACKUPSTATUSES_MENU);
      break;
    case downloadStatusesPath:
      setActivePageMenu(constant.MENUS.DOWNLOADSTATUSES_MENU);
      break;
    case restoreStatusesPath:
      setActivePageMenu(constant.MENUS.RESTORESTATUSES_MENU);
      break;
    case migrateStatusesPath:
      setActivePageMenu(constant.MENUS.MIGRATESTATUSES_MENU);
      break;
    case gwsTenantDetailPath:
      setActivePageMenu(constant.MENUS.GWS_TENANT_DETAILS);
      break;
    case hexTenantListPath:
      setActivePageMenu(constant.MENUS.TENANT_HEX_INFO);
      break;
    case hexTenantDetailPath:
      setActivePageMenu(constant.MENUS.TENANT_HEX_INFO);
      break;
    default:
      setActivePageMenu(constant.MENUS.HOME_MENU);
  }

  return {
    setActivePageMenu,
    isActiveMenu,
    listMenu: constant.MENUS,
  };
}
