import { organizationInformationPath, resellersListPath } from '@config/Routes/WebClientRoutes';

export const MIGRATE_STATUS_COLUMNS = [
  { label: 'ID', columnName: 'migrateId' },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
    navigateURL: (row) =>
      `${organizationInformationPath}?id=${row.organizationOwnerId}&name=${row.organizationName}`,
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
    navigateURL: (row) => `${resellersListPath}?id=${row.partnerId}&name=${row.partnerName}`,
  },
  { label: 'Migrate Status', columnName: 'status', size: 'sm' },
  { label: 'Email Account ID', columnName: 'emailAccountId' },
  { label: 'Destination Email Account ID', columnName: 'destinationEmailAccountId' },
  { label: 'Email', columnName: 'email', size: 'sm' },
  { label: 'UPN', columnName: 'upn', size: 'sm' },
  { label: 'Username', columnName: 'username', size: 'sm' },
  { label: 'Display Name', columnName: 'displayName', size: 'sm' },
  { label: 'Mailbox ID', columnName: 'mailboxId', size: 'sm' },
  { label: 'Shared Mailbox Login', columnName: 'sharedMailboxLogin', size: 'sm' },
  { label: 'Shared Mailbox Type', columnName: 'sharedMailboxType', size: 'sm' },
  { label: 'Exchange Admin Mailbox ID', columnName: 'exchangeAdminMailboxId', size: 'sm' },
  { label: 'Start Migrate Time', columnName: 'startTime', size: 'sm' },
  { label: 'End Migrate Time', columnName: 'endTime', size: 'sm' },
  { label: 'Elapsed Time', columnName: 'elapsedTime', size: 'sm' },
  { label: 'Message IDs', columnName: 'messageIds', size: 'sm' },
  { label: 'Host', columnName: 'host', size: 'sm' },
  { label: 'Protocol', columnName: 'protocol', size: 'sm' },
  { label: 'Start TLS', columnName: 'startTls', size: 'sm' },
  { label: 'SSL', columnName: 'ssl', size: 'sm' },
  { label: 'Port', columnName: 'port', size: 'sm' },
  { label: 'Test Message', columnName: 'testMessage', size: 'sm' },
  { label: 'Test Status', columnName: 'testStatus', size: 'sm' },
  { label: 'Setup Completed', columnName: 'completedSetup', size: 'sm' },
  { label: 'Downloaded', columnName: 'downloaded', size: 'sm' },
  { label: 'Migrate Size', columnName: 'migrateSize', size: 'sm' },
  { label: 'Current Message Numbers', columnName: 'currentMessagesCount', size: 'sm' },
  { label: 'Total Message Numbers', columnName: 'totalMessagesCount', size: 'sm' },
  { label: 'From Date', columnName: 'fromDate', size: 'sm' },
  { label: 'To Date', columnName: 'toDate', size: 'sm' },
  { label: 'Retries Time', columnName: 'retries', size: 'sm' },
  { label: 'Error Messages', columnName: 'errorMessage', size: 'sm' },
  { label: 'Query Params', columnName: 'queryParams', size: 'sm' },
  { label: 'Additional Data', columnName: 'additionalData', size: 'sm' },
  { label: 'OAuth Type', columnName: 'oAuthType', size: 'sm' },
  { label: 'Run on Machine IP', columnName: 'machineIP', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
  { label: 'Cancelled At', columnName: 'cancelledAt', size: 'sm' },
  { label: 'Cancelled By', columnName: 'cancelledBy', size: 'sm' },
];

export const systemStatusItems = [
  {
    id: 1,
    value: 'completed',
    title: 'Completed',
  },
  {
    id: 2,
    value: 'running',
    title: 'Running',
  },
  {
    id: 3,
    value: 'error',
    title: 'Error',
  },
];

export const buttonStyle = {
  height: 36,
  backgroundColor: '#a5a5a5',
  textDecoration: 'none',
  color: 'black',
  '&:hover': {
    backgroundColor: '#a5a5a5',
  },
  textTransform: 'unset',
  fontSize: 14,
};
