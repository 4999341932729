import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { QboCard } from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import QboTitleWithCollapsableButton from '@ui/Components/QboTitleWithCollapsableButton';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import EditResellerConfigsModal from '@ui/Sections/PartnerListPage/EditResellerConfigsModal';
import ResellerSettingsSection from '@ui/Sections/PartnerListPage/ResellerSettingsSection';
import ResellerMsGroupSettingsSection from '@ui/Sections/PartnerListPage/ResellerMsGroupSettingsSection';
import EditResellerMsGroupConfigsModal from '@ui/Sections/PartnerListPage/EditResellerMsGroupConfigsModal';
import EditResellerImpersonateConfigsModal from '@ui/Sections/PartnerListPage/EditResellerImpersonateConfigsModal';
import ResellerImpersonateSettingsSection from '@ui/Sections/PartnerListPage/ResellerImpersonateSettingsSection';
import ResellerImpersonateFeatureSettingsSection from '@ui/Sections/PartnerListPage/ResellerImpersonateFeatureSettingsSection';
import { useTranslation } from 'react-i18next';
import useResellerService from './pageHooks/useResellerService';
import useResellerLabelDataService from './pageHooks/useResellerLabelDataService';

export default function ResellersPage(props) {
  const { resellerID, resellerName, parentId } = props;
  const { t } = useTranslation();
  const { handleGetSelectedResellerConfigsAsync } = useResellerService();
  const { getSelectedResellerConfigsState } = useSelector(
    (state) => state.selectedResellerConfigsState
  );
  const [permissionsData, setPermissionsData] = useState({});
  const [mgGroupSettingsData, setMgGroupSettingsData] = useState({});
  const [editResellerModalState, setEditResellerModalState] = useState({});
  const [editMsGroupModalState, setEditMsGroupModalState] = useState({});
  const [editImpersonateModalState, setEditImpersonateModalState] = useState({});
  const styleObj = { display: 'flex', justifyContent: 'space-between' };

  const {
    apiSettings,
    resellerDetails,
    resellerDetailsPartnerType,
    resellerDetailsPortalSettings,
    resellerPermissions,
    resellerPermissionsAdminPrivileges,
    resellerPermissionsContractSettings,
    resellerPermissionsDistributorMasterResellerPermissions,
    resellerPermissionsFeatures,
    resellerPermissionsFeaturesLoginAsClient,
    resellerPermissionsFeaturesNFR,
    resellerPermissionsFeaturesNonProfit,
    resellerPermissionsFeaturesQBO,
    resellerPermissionsOrgSettingsOrgPermissionsAccountsActivities,
    resellerPermissionsOrgSettingsOrgPermissionsEnableRestoreMigrateDownloadLimit,
    resellerPermissionsOrgSettingsOrgPermissionsJournalAccountsArchiverPlanOnly,
    resellerPermissionsOrgSettingsOrgPermissionsOrganisationPreferences,
    resellerPermissionsOrgSettingsOrgPermissionsOthers,
    resellerPermissionsOrgSettingsOrgPermissionsSavedSearchArchiverPlanOnly,
    resellerPermissionsOrgSettingsOrgPermissionsUserManagementBackupAndArchiverPlanOnly,
    resellerPermissionsPaymentSettings,
    resellerPermissionsTrial,
    resellerPricingLabels,
    resellerPermissionsFeaturesLoginAsClientPermissions,
    resellerPermissionsFeaturesLoginAsClientView,
    resellerPermissionsFeaturesLoginAsClientOthers,
    resellerPermissionsFeaturesMsGroupSettings,
    smtpSettingsLabels,
  } = useResellerLabelDataService({ parentId });

  const [flagsList, setFlagsList] = useState({
    resellerDetails: true,
    partnerType: false,
    portalSettings: false,
    apiSettings: false,
    smtpSettings: false,
    resellerPermissions: false,
    adminPrivileges: false,
    contractSettings: false,
    features: false,
    loginAsClient: false,
    featuresNFR: false,
    featuresQBO: false,
    nonProfit: false,
    organisationSettings: false,
    organisationPermissions: false,
    accountsActivities: false,
    enableRestoreMigrateDownloadLimit: false,
    journalAccountsArchiverPlanOnly: false,
    organisationPreferences: false,
    savedSearchArchiverPlanOnly: false,
    userManagementBackupAndArchiverPlanOnly: false,
    paymentSettings: false,
    trial: false,
    permissions: false,
    preview: false,
    restore: false,
    download: false,
    migrate: false,
    resellerPermissionsOthers: false,
    mSGroupsSettings: false,
    resellerPricing: false,
    distributorMasterResellerPermissions: false,
    others: false,
    views: false,
  });

  const { fetching, selectedResellerConfigdata } = getSelectedResellerConfigsState;

  useEffect(() => {
    if (
      resellerID &&
      !Object.keys(
        (selectedResellerConfigdata?.resellerSettings &&
          selectedResellerConfigdata?.resellerSettings[0]) ||
          {}
      ).length > 0
    ) {
      handleGetSelectedResellerConfigsAsync(resellerID);
    }
  }, [resellerID]);

  const handleOnIconButtonClick = (e, flagType) => {
    setFlagsList((prevState) => ({
      ...prevState,
      [flagType]: !prevState[flagType],
    }));
  };

  const handleEditResellerSave = (editData) => {
    setEditResellerModalState({
      editData,
      show: true,
    });
  };

  const handleCloseEditResellerModal = () => {
    setEditResellerModalState({});
  };

  const handleEditMsGroupSave = (editMsGroupData) => {
    setEditMsGroupModalState({
      ...editMsGroupData,
      show: true,
    });
  };

  const handleCloseEditMsGroupModal = () => {
    setEditMsGroupModalState({});
  };

  const handleEditImpersonateSave = (editImpersonateData) => {
    setEditImpersonateModalState({
      ...editImpersonateData,
      show: true,
    });
  };

  const handleCloseEditImpersonateModal = () => {
    setEditImpersonateModalState({});
  };

  const handleEditSuccess = () => {
    setEditResellerModalState({});
    setEditMsGroupModalState({});
    setEditImpersonateModalState({});
    if (resellerID) {
      handleGetSelectedResellerConfigsAsync(resellerID);
    }
  };

  const valueHandlerSmtp = (label, value, valType = '') => {
    const frequencyMap = {
      d: 'Daily',
      w: 'Weekly',
      m: 'Monthly',
    };

    const result =
      selectedResellerConfigdata?.resellerSmtpSettings &&
      selectedResellerConfigdata?.resellerSmtpSettings[0][value];
    if (valType === 'bool' && result === null) {
      return 'false';
    }
    if (result === null) {
      return '-';
    }
    if (label === 'Notify Frequency' && frequencyMap[result]) {
      return frequencyMap[result];
    }
    return `${result}`;
  };

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  useEffect(() => {
    if (
      selectedResellerConfigdata?.resellerSettings &&
      selectedResellerConfigdata?.resellerSettings?.length > 0 &&
      Object.keys(selectedResellerConfigdata?.resellerSettings[0]).length > 0
    ) {
      setMgGroupSettingsData(
        JSON.parse(selectedResellerConfigdata?.resellerSettings[0]?.defaultMSGroupAbilities)
      );
      setPermissionsData(
        JSON.parse(selectedResellerConfigdata?.resellerSettings[0]?.resellerImpersonatePermissions)
      );
    }
  }, [selectedResellerConfigdata]);

  return (
    <>
      {!fetching &&
        selectedResellerConfigdata?.resellerSettings &&
        selectedResellerConfigdata?.resellerSettings?.length > 0 &&
        Object.keys(selectedResellerConfigdata?.resellerSettings[0]).length > 0 && (
          <QboCard
            className="QboCard__wrapper_search_text_highlight"
            sx={{
              scrollbarWidth: 'none !important',
              overflowY: 'scroll !important',
              height: '65vh',
            }}>
            <QboCard sx={{ marginTop: 2, marginBottom: 2 }}>
              <QboTitleWithCollapsableButton
                title="Reseller Details"
                variant="h5"
                flagType="resellerDetails"
                open={flagsList.resellerDetails}
                isEditEnable={false}
                style={styleObj}
                handleOnIconButtonClick={(e) => {
                  handleOnIconButtonClick(e, 'resellerDetails');
                }}
              />
              {flagsList.resellerDetails && (
                <>
                  <ResellerSettingsSection
                    data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                    labels={resellerDetails}
                    onSave={handleEditResellerSave}
                  />
                  <QboCard>
                    <QboTitleWithCollapsableButton
                      sx={{ fontSize: 16 }}
                      title="Partner Type"
                      flagType="partnerType"
                      open={flagsList.partnerType}
                      isEditEnable={false}
                      style={styleObj}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'partnerType');
                      }}
                    />
                    {Boolean(flagsList.partnerType) && (
                      <ResellerSettingsSection
                        data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                        labels={resellerDetailsPartnerType}
                        onSave={handleEditResellerSave}
                      />
                    )}
                    <QboTitleWithCollapsableButton
                      sx={{ fontSize: 16 }}
                      title="Portal Settings"
                      flagType="portalSettings"
                      open={flagsList.portalSettings}
                      isEditEnable={false}
                      style={{ marginTop: 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'portalSettings');
                      }}
                    />
                    {Boolean(flagsList.portalSettings) && (
                      <ResellerSettingsSection
                        data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                        labels={resellerDetailsPortalSettings}
                        onSave={handleEditResellerSave}
                      />
                    )}
                  </QboCard>
                </>
              )}
            </QboCard>
            <QboCard sx={{ marginBottom: 2 }}>
              <QboTitleWithCollapsableButton
                sx={{ marginLeft: 1 }}
                title="API Settings"
                variant="h5"
                flagType="apiSettings"
                open={flagsList.apiSettings}
                isEditEnable={false}
                style={styleObj}
                handleOnIconButtonClick={(e) => {
                  handleOnIconButtonClick(e, 'apiSettings');
                }}
              />
              {flagsList.apiSettings && (
                <ResellerSettingsSection
                  data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                  labels={apiSettings}
                  onSave={handleEditResellerSave}
                />
              )}
            </QboCard>
            <QboCard sx={{ marginBottom: 2 }}>
              <QboTitleWithCollapsableButton
                title="Reseller Permissions"
                variant="h5"
                flagType="resellerPermissions"
                open={flagsList.resellerPermissions}
                isEditEnable={false}
                style={styleObj}
                handleOnIconButtonClick={(e) => {
                  handleOnIconButtonClick(e, 'resellerPermissions');
                }}
              />
              {flagsList.resellerPermissions && (
                <>
                  <ResellerSettingsSection
                    data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                    labels={resellerPermissions}
                    onSave={handleEditResellerSave}
                  />
                  <QboCard>
                    <QboTitleWithCollapsableButton
                      sx={{ fontSize: 16 }}
                      title=" Admin Privileges"
                      flagType="adminPrivileges"
                      open={flagsList.adminPrivileges}
                      isEditEnable={false}
                      style={styleObj}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'adminPrivileges');
                      }}
                    />
                    {Boolean(flagsList.adminPrivileges) && (
                      <ResellerSettingsSection
                        data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                        labels={resellerPermissionsAdminPrivileges}
                        onSave={handleEditResellerSave}
                      />
                    )}
                    <QboTitleWithCollapsableButton
                      sx={{ fontSize: 16 }}
                      title="Contract Settings"
                      flagType="contractSettings"
                      open={flagsList.contractSettings}
                      isEditEnable={false}
                      style={{ marginTop: 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'contractSettings');
                      }}
                    />
                    {Boolean(flagsList.contractSettings) && (
                      <ResellerSettingsSection
                        data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                        labels={resellerPermissionsContractSettings}
                        onSave={handleEditResellerSave}
                      />
                    )}

                    <QboTitleWithCollapsableButton
                      sx={{ fontSize: 16 }}
                      title="Distributor / Master Reseller Permissions"
                      flagType="distributorMasterResellerPermissions"
                      open={flagsList.distributorMasterResellerPermissions}
                      isEditEnable={false}
                      style={{ marginTop: 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'distributorMasterResellerPermissions');
                      }}
                    />
                    {Boolean(flagsList.distributorMasterResellerPermissions) && (
                      <ResellerSettingsSection
                        data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                        labels={resellerPermissionsDistributorMasterResellerPermissions}
                        onSave={handleEditResellerSave}
                      />
                    )}
                    <QboTitleWithCollapsableButton
                      sx={{ fontSize: 16 }}
                      title=" Features"
                      flagType="contractSettings"
                      open={flagsList.features}
                      isEditEnable={false}
                      style={{ marginTop: 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'features');
                      }}
                    />
                    {Boolean(flagsList.features) && (
                      <>
                        <ResellerSettingsSection
                          data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                          labels={resellerPermissionsFeatures}
                          onSave={handleEditResellerSave}
                        />
                        <QboCard className="ResellersPage__settings-card">
                          <QboTitleWithCollapsableButton
                            marginRightIconButton="4.5%"
                            sx={{ fontSize: 16 }}
                            title="MS Groups Settings"
                            flagType="mSGroupsSettings"
                            open={flagsList.mSGroupsSettings}
                            isEditEnable={false}
                            style={{ marginTop: 2, ...styleObj }}
                            handleOnIconButtonClick={(e) => {
                              handleOnIconButtonClick(e, 'mSGroupsSettings');
                            }}
                          />
                          {Boolean(flagsList.mSGroupsSettings) && (
                            <ResellerMsGroupSettingsSection
                              data={mgGroupSettingsData}
                              onSave={handleEditMsGroupSave}
                            />
                          )}
                          <QboTitleWithCollapsableButton
                            marginRightIconButton="4.5%"
                            sx={{ fontSize: 16 }}
                            title="Login as Client"
                            flagType="loginAsClient"
                            open={flagsList.loginAsClient}
                            isEditEnable={false}
                            style={{ marginTop: 2, ...styleObj }}
                            handleOnIconButtonClick={(e) => {
                              handleOnIconButtonClick(e, 'loginAsClient');
                            }}
                          />
                          {Boolean(flagsList.loginAsClient) && (
                            <>
                              <ResellerSettingsSection
                                data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                                labels={resellerPermissionsFeaturesLoginAsClient}
                                onSave={handleEditResellerSave}
                              />
                              <QboCard className="ResellersPage__settings-card">
                                <QboTitleWithCollapsableButton
                                  marginRightIconButton="6%"
                                  sx={{ fontSize: 16 }}
                                  title="Permissions"
                                  flagType="permissions"
                                  open={flagsList.permissions}
                                  isEditEnable={false}
                                  style={{ marginTop: 2, ...styleObj }}
                                  handleOnIconButtonClick={(e) => {
                                    handleOnIconButtonClick(e, 'permissions');
                                  }}
                                />
                                {Boolean(flagsList.permissions) && (
                                  <ResellerImpersonateSettingsSection
                                    data={permissionsData}
                                    labels={resellerPermissionsFeaturesLoginAsClientPermissions}
                                    onSave={handleEditImpersonateSave}
                                    disableEdit={
                                      !selectedResellerConfigdata?.resellerSettings[0]
                                        ?.resellerImpersonate
                                    }
                                    disableEditTooltip={t(
                                      'partner_list_page.edit_reseller_configs.validations.impersonate_disabled'
                                    )}
                                  />
                                )}
                                <QboTitleWithCollapsableButton
                                  marginRightIconButton="6%"
                                  sx={{ fontSize: 16 }}
                                  title="View"
                                  flagType="views"
                                  open={flagsList.views}
                                  isEditEnable={false}
                                  style={{ marginTop: 2, ...styleObj }}
                                  handleOnIconButtonClick={(e) => {
                                    handleOnIconButtonClick(e, 'views');
                                  }}
                                />
                                {Boolean(flagsList.views) && (
                                  <ResellerImpersonateFeatureSettingsSection
                                    data={permissionsData}
                                    feature="view"
                                    onSave={handleEditImpersonateSave}
                                    disableEdit={
                                      !selectedResellerConfigdata?.resellerSettings[0]
                                        ?.resellerImpersonate
                                    }
                                    disableEditTooltip={t(
                                      'partner_list_page.edit_reseller_configs.validations.impersonate_disabled'
                                    )}
                                  />
                                )}

                                <QboTitleWithCollapsableButton
                                  marginRightIconButton="6%"
                                  sx={{ fontSize: 16 }}
                                  title="Preview"
                                  flagType="preview"
                                  open={flagsList.preview}
                                  isEditEnable={false}
                                  style={{ marginTop: 2, ...styleObj }}
                                  handleOnIconButtonClick={(e) => {
                                    handleOnIconButtonClick(e, 'preview');
                                  }}
                                />
                                {Boolean(flagsList.preview) && (
                                  <ResellerImpersonateFeatureSettingsSection
                                    data={permissionsData}
                                    feature="preview"
                                    onSave={handleEditImpersonateSave}
                                    disableEdit={
                                      !selectedResellerConfigdata?.resellerSettings[0]
                                        ?.resellerImpersonate
                                    }
                                    disableEditTooltip={t(
                                      'partner_list_page.edit_reseller_configs.validations.impersonate_disabled'
                                    )}
                                  />
                                )}
                                <QboTitleWithCollapsableButton
                                  marginRightIconButton="6%"
                                  sx={{ fontSize: 16 }}
                                  title="Restore"
                                  flagType="restore"
                                  open={flagsList.restore}
                                  isEditEnable={false}
                                  style={{ marginTop: 2, ...styleObj }}
                                  handleOnIconButtonClick={(e) => {
                                    handleOnIconButtonClick(e, 'restore');
                                  }}
                                />
                                {Boolean(flagsList.restore) && (
                                  <ResellerImpersonateFeatureSettingsSection
                                    data={permissionsData}
                                    feature="restore"
                                    onSave={handleEditImpersonateSave}
                                    disableEdit={
                                      !selectedResellerConfigdata?.resellerSettings[0]
                                        ?.resellerImpersonate
                                    }
                                    disableEditTooltip={t(
                                      'partner_list_page.edit_reseller_configs.validations.impersonate_disabled'
                                    )}
                                  />
                                )}
                                <QboTitleWithCollapsableButton
                                  marginRightIconButton="6%"
                                  sx={{ fontSize: 16 }}
                                  title="Download"
                                  flagType="download"
                                  open={flagsList.download}
                                  isEditEnable={false}
                                  style={{ marginTop: 2, ...styleObj }}
                                  handleOnIconButtonClick={(e) => {
                                    handleOnIconButtonClick(e, 'download');
                                  }}
                                />
                                {Boolean(flagsList.download) && (
                                  <ResellerImpersonateFeatureSettingsSection
                                    data={permissionsData}
                                    feature="download"
                                    onSave={handleEditImpersonateSave}
                                    disableEdit={
                                      !selectedResellerConfigdata?.resellerSettings[0]
                                        ?.resellerImpersonate
                                    }
                                    disableEditTooltip={t(
                                      'partner_list_page.edit_reseller_configs.validations.impersonate_disabled'
                                    )}
                                  />
                                )}
                                <QboTitleWithCollapsableButton
                                  marginRightIconButton="6%"
                                  sx={{ fontSize: 16 }}
                                  title="Migrate"
                                  flagType="migrate"
                                  open={flagsList.migrate}
                                  isEditEnable={false}
                                  style={{ marginTop: 2, ...styleObj }}
                                  handleOnIconButtonClick={(e) => {
                                    handleOnIconButtonClick(e, 'migrate');
                                  }}
                                />
                                {Boolean(flagsList.migrate) && (
                                  <ResellerImpersonateFeatureSettingsSection
                                    data={permissionsData}
                                    feature="migrate"
                                    onSave={handleEditImpersonateSave}
                                    disableEdit={
                                      !selectedResellerConfigdata?.resellerSettings[0]
                                        ?.resellerImpersonate
                                    }
                                    disableEditTooltip={t(
                                      'partner_list_page.edit_reseller_configs.validations.impersonate_disabled'
                                    )}
                                  />
                                )}
                                <QboTitleWithCollapsableButton
                                  marginRightIconButton="6%"
                                  sx={{ fontSize: 16 }}
                                  title="Others"
                                  flagType="resellerPermissionsOthers"
                                  open={flagsList.resellerPermissionsOthers}
                                  isEditEnable={false}
                                  style={{ marginTop: 2, ...styleObj }}
                                  handleOnIconButtonClick={(e) => {
                                    handleOnIconButtonClick(e, 'resellerPermissionsOthers');
                                  }}
                                />
                                {Boolean(flagsList.resellerPermissionsOthers) && (
                                  <ResellerImpersonateSettingsSection
                                    data={permissionsData}
                                    labels={resellerPermissionsFeaturesLoginAsClientOthers}
                                    onSave={handleEditImpersonateSave}
                                    disableEdit={
                                      !selectedResellerConfigdata?.resellerSettings[0]
                                        ?.resellerImpersonate
                                    }
                                    disableEditTooltip={t(
                                      'partner_list_page.edit_reseller_configs.validations.impersonate_disabled'
                                    )}
                                  />
                                )}
                              </QboCard>
                            </>
                          )}
                          <QboTitleWithCollapsableButton
                            marginRightIconButton="4.5%"
                            sx={{ fontSize: 16 }}
                            title="NFR"
                            flagType="featuresNFR"
                            open={flagsList.featuresNFR}
                            isEditEnable={false}
                            style={{
                              marginTop: flagsList.loginAsClient ? 4 : 2,
                              ...styleObj,
                            }}
                            handleOnIconButtonClick={(e) => {
                              handleOnIconButtonClick(e, 'featuresNFR');
                            }}
                          />
                          {Boolean(flagsList.featuresNFR) && (
                            <ResellerSettingsSection
                              data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                              labels={resellerPermissionsFeaturesNFR}
                              onSave={handleEditResellerSave}
                            />
                          )}

                          <QboTitleWithCollapsableButton
                            marginRightIconButton="4.5%"
                            sx={{ fontSize: 16 }}
                            title="Non-Profit"
                            flagType="nonProfit"
                            open={flagsList.nonProfit}
                            isEditEnable={false}
                            style={{ marginTop: 2, ...styleObj }}
                            handleOnIconButtonClick={(e) => {
                              handleOnIconButtonClick(e, 'nonProfit');
                            }}
                          />
                          {Boolean(flagsList.nonProfit) && (
                            <ResellerSettingsSection
                              data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                              labels={resellerPermissionsFeaturesNonProfit}
                              onSave={handleEditResellerSave}
                            />
                          )}
                          <QboTitleWithCollapsableButton
                            marginRightIconButton="4.5%"
                            sx={{ fontSize: 16 }}
                            title="QBO"
                            flagType="featuresQBO"
                            open={flagsList.featuresQBO}
                            isEditEnable={false}
                            style={{ marginTop: 2, ...styleObj }}
                            handleOnIconButtonClick={(e) => {
                              handleOnIconButtonClick(e, 'featuresQBO');
                            }}
                          />
                          {Boolean(flagsList.featuresQBO) && (
                            <ResellerSettingsSection
                              data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                              labels={resellerPermissionsFeaturesQBO}
                              onSave={handleEditResellerSave}
                            />
                          )}
                        </QboCard>
                      </>
                    )}
                    <QboTitleWithCollapsableButton
                      sx={{ fontSize: 16 }}
                      title="Organisation Settings"
                      flagType="organisationSettings"
                      open={flagsList.organisationSettings}
                      isEditEnable={false}
                      style={{ marginTop: flagsList.features ? 4 : 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'organisationSettings');
                      }}
                    />
                    {flagsList.organisationSettings && (
                      <QboCard className="ResellersPage__settings-card">
                        <QboTitleWithCollapsableButton
                          marginRightIconButton="4.5%"
                          sx={{ fontSize: 16 }}
                          title="Accounts Activities"
                          flagType="accountsActivities"
                          open={flagsList.accountsActivities}
                          isEditEnable={false}
                          style={{ marginTop: 2, ...styleObj }}
                          handleOnIconButtonClick={(e) => {
                            handleOnIconButtonClick(e, 'accountsActivities');
                          }}
                        />
                        {Boolean(flagsList.accountsActivities) && (
                          <ResellerSettingsSection
                            data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                            labels={resellerPermissionsOrgSettingsOrgPermissionsAccountsActivities}
                            onSave={handleEditResellerSave}
                          />
                        )}
                        <QboTitleWithCollapsableButton
                          marginRightIconButton="4.5%"
                          sx={{ fontSize: 16 }}
                          title="Enable Restore/Migrate/Download Limit"
                          flagType="enableRestoreMigrateDownloadLimit"
                          open={flagsList.enableRestoreMigrateDownloadLimit}
                          isEditEnable={false}
                          style={{ marginTop: 2, ...styleObj }}
                          handleOnIconButtonClick={(e) => {
                            handleOnIconButtonClick(e, 'enableRestoreMigrateDownloadLimit');
                          }}
                        />
                        {Boolean(flagsList.enableRestoreMigrateDownloadLimit) && (
                          <ResellerSettingsSection
                            data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                            labels={
                              resellerPermissionsOrgSettingsOrgPermissionsEnableRestoreMigrateDownloadLimit
                            }
                            onSave={handleEditResellerSave}
                          />
                        )}
                        <QboTitleWithCollapsableButton
                          marginRightIconButton="4.5%"
                          sx={{ fontSize: 16 }}
                          title="Journal Accounts (Archiver plan only)"
                          flagType="journalAccountsArchiverPlanOnly"
                          open={flagsList.journalAccountsArchiverPlanOnly}
                          isEditEnable={false}
                          style={{ marginTop: 2, ...styleObj }}
                          handleOnIconButtonClick={(e) => {
                            handleOnIconButtonClick(e, 'journalAccountsArchiverPlanOnly');
                          }}
                        />
                        {Boolean(flagsList.journalAccountsArchiverPlanOnly) && (
                          <ResellerSettingsSection
                            data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                            labels={
                              resellerPermissionsOrgSettingsOrgPermissionsJournalAccountsArchiverPlanOnly
                            }
                            onSave={handleEditResellerSave}
                          />
                        )}
                        <QboTitleWithCollapsableButton
                          marginRightIconButton="4.5%"
                          sx={{ fontSize: 16 }}
                          title="Organisation Preferences"
                          flagType="organisationPreferences"
                          open={flagsList.organisationPreferences}
                          isEditEnable={false}
                          style={{ marginTop: 2, ...styleObj }}
                          handleOnIconButtonClick={(e) => {
                            handleOnIconButtonClick(e, 'organisationPreferences');
                          }}
                        />
                        {Boolean(flagsList.organisationPreferences) && (
                          <ResellerSettingsSection
                            data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                            labels={
                              resellerPermissionsOrgSettingsOrgPermissionsOrganisationPreferences
                            }
                            onSave={handleEditResellerSave}
                          />
                        )}
                        <QboTitleWithCollapsableButton
                          marginRightIconButton="4.5%"
                          sx={{ fontSize: 16 }}
                          title="Others"
                          flagType="others"
                          open={flagsList.others}
                          isEditEnable={false}
                          style={{ marginTop: 2, ...styleObj }}
                          handleOnIconButtonClick={(e) => {
                            handleOnIconButtonClick(e, 'others');
                          }}
                        />
                        {Boolean(flagsList.others) && (
                          <ResellerSettingsSection
                            data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                            labels={resellerPermissionsOrgSettingsOrgPermissionsOthers}
                            onSave={handleEditResellerSave}
                          />
                        )}

                        <QboTitleWithCollapsableButton
                          marginRightIconButton="4.5%"
                          sx={{ fontSize: 16 }}
                          title="Saved Search (Archiver plan only)"
                          flagType="savedSearchArchiverPlanOnly"
                          open={flagsList.savedSearchArchiverPlanOnly}
                          isEditEnable={false}
                          style={{ marginTop: 2, ...styleObj }}
                          handleOnIconButtonClick={(e) => {
                            handleOnIconButtonClick(e, 'savedSearchArchiverPlanOnly');
                          }}
                        />
                        {Boolean(flagsList.savedSearchArchiverPlanOnly) && (
                          <ResellerSettingsSection
                            data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                            labels={
                              resellerPermissionsOrgSettingsOrgPermissionsSavedSearchArchiverPlanOnly
                            }
                            onSave={handleEditResellerSave}
                          />
                        )}
                        <QboTitleWithCollapsableButton
                          marginRightIconButton="4.5%"
                          sx={{ fontSize: 16 }}
                          title="User Management (Backup & Archiver plan only)"
                          flagType="userManagementBackupAndArchiverPlanOnly"
                          open={flagsList.userManagementBackupAndArchiverPlanOnly}
                          isEditEnable={false}
                          style={{ marginTop: 2, ...styleObj }}
                          handleOnIconButtonClick={(e) => {
                            handleOnIconButtonClick(e, 'userManagementBackupAndArchiverPlanOnly');
                          }}
                        />
                        {Boolean(flagsList.userManagementBackupAndArchiverPlanOnly) && (
                          <ResellerSettingsSection
                            data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                            labels={
                              resellerPermissionsOrgSettingsOrgPermissionsUserManagementBackupAndArchiverPlanOnly
                            }
                            onSave={handleEditResellerSave}
                          />
                        )}
                      </QboCard>
                    )}
                    <QboTitleWithCollapsableButton
                      sx={{ fontSize: 16 }}
                      title="Payment Settings"
                      flagType="paymentSettings"
                      open={flagsList.paymentSettings}
                      isEditEnable={false}
                      style={{ marginTop: flagsList.organisationSettings ? 4 : 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'paymentSettings');
                      }}
                    />
                    {Boolean(flagsList.paymentSettings) && (
                      <ResellerSettingsSection
                        data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                        labels={resellerPermissionsPaymentSettings}
                        onSave={handleEditResellerSave}
                      />
                    )}
                    <QboTitleWithCollapsableButton
                      sx={{ fontSize: 16 }}
                      title="Trial"
                      flagType="trial"
                      open={flagsList.trial}
                      isEditEnable={false}
                      style={{ marginTop: flagsList.organisationSettings ? 4 : 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'trial');
                      }}
                    />
                    {Boolean(flagsList.trial) && (
                      <ResellerSettingsSection
                        data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                        labels={resellerPermissionsTrial}
                        onSave={handleEditResellerSave}
                      />
                    )}
                  </QboCard>
                </>
              )}
            </QboCard>
            <QboCard sx={{ marginBottom: 2 }}>
              <QboTitleWithCollapsableButton
                title="Reseller Pricing"
                variant="h5"
                flagType="resellerPricing"
                open={flagsList.resellerPricing}
                isEditEnable={false}
                style={styleObj}
                handleOnIconButtonClick={(e) => {
                  handleOnIconButtonClick(e, 'resellerPricing');
                }}
              />
              {Boolean(flagsList.resellerPricing) && (
                <ResellerSettingsSection
                  data={selectedResellerConfigdata?.resellerSettings[0] ?? {}}
                  labels={resellerPricingLabels}
                  onSave={handleEditResellerSave}
                />
              )}
            </QboCard>
            <QboCard>
              <QboTitleWithCollapsableButton
                title="SMTP Settings"
                variant="h5"
                flagType="smtpSettings"
                open={flagsList.smtpSettings}
                isEditEnable={false}
                style={styleObj}
                handleOnIconButtonClick={(e) => {
                  handleOnIconButtonClick(e, 'smtpSettings');
                }}
              />
              {Boolean(flagsList.smtpSettings) &&
                smtpSettingsLabels?.map((notification) => (
                  <QboCardSingleList
                    subHeadingText={notification?.subHeadingText}
                    leftContent={notification.label}
                    rightContent={valueHandlerSmtp(
                      notification.label,
                      notification.properties,
                      notification.valType
                    )}
                  />
                ))}
            </QboCard>
          </QboCard>
        )}
      {fetching && renderLoading()}
      {!fetching && selectedResellerConfigdata?.resellerSettings?.length === 0 && (
        <NoStatusSection />
      )}
      <EditResellerConfigsModal
        onSuccess={handleEditSuccess}
        closeModal={handleCloseEditResellerModal}
        showModal={editResellerModalState?.show ?? false}
        editData={editResellerModalState?.editData ?? []}
        resellerId={resellerID}
        resellerName={resellerName}
      />
      <EditResellerMsGroupConfigsModal
        onSuccess={handleEditSuccess}
        closeModal={handleCloseEditMsGroupModal}
        showModal={editMsGroupModalState?.show ?? false}
        preview={editMsGroupModalState?.preview ?? []}
        msGroupSettings={editMsGroupModalState?.msGroupSettings ?? {}}
        resellerId={resellerID}
        resellerName={resellerName}
      />
      <EditResellerImpersonateConfigsModal
        onSuccess={handleEditSuccess}
        closeModal={handleCloseEditImpersonateModal}
        showModal={editImpersonateModalState?.show ?? false}
        preview={editImpersonateModalState?.preview ?? []}
        impersonateSettings={editImpersonateModalState?.impersonateSettings ?? {}}
        resellerId={resellerID}
        resellerName={resellerName}
      />
    </>
  );
}

ResellersPage.propTypes = {
  resellerID: PropTypes.number,
  resellerName: PropTypes.string,
  parentId: PropTypes.number,
};

ResellersPage.defaultProps = {
  resellerID: null,
  resellerName: null,
  parentId: null,
};
