import { constant } from '@config/BaseSetting';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import { showFixedAlert } from '@features/application/applicationSlice';
import {
  bulkExcludeMailboxAsync,
  resetBulkExcludeMailboxState,
} from '@features/bulkExcludeMailbox/bulkExcludeMailboxSlice';
import useEmailFromCSV from '@hooks/useEmailFromCSV';
import useForm from '@hooks/useForm';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

const formSchema = yup.object().shape({
  supportTicket: yup.string().required('Support Ticket is required').default(''),
  escalationTicket: yup.string().default(''),
  description: yup.string().default(''),
  credentialId: yup
    .string()
    .required('Credential ID is required')
    .matches(/^(?:\d+)?$/, 'Credential ID must be a number')
    .default(''),
  credentialType: yup
    .string()
    .required('Credential Type is required')
    .matches(/^(?:\d+)?$/, 'Invalid Credential Type')
    .default('3'),
  emails: yup.string().required('Emails are required').default(''),
});

const credentialTypeOptions = [
  { id: '3', label: 'M365' },
  { id: '4', label: 'GWS' },
];

export default function useBulkExcludeMailbox({ organization, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { bulkExcludeMailboxState } = useSelector((state) => state.bulkExcludeMailbox);
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { handleOnFileUpload } = useEmailFromCSV();
  const form = useForm(formSchema);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (form.isValid) {
      const parameters = {
        'support-ticket': form.values.supportTicket,
        'escalation-ticket': form.values.escalationTicket,
        description: form.values.description,
        'org-owner-id': organization.organizationOwnerId,
        'credential-id': parseInt(form.values.credentialId, 10),
        protocol: parseInt(form.values.credentialType, 10),
        emails: form.values.emails.split(','),
      };
      dispatch(
        bulkExcludeMailboxAsync({
          accessTokenSet,
          reset: true,
          parameters,
        })
      );
    }
  }, [form.isValid]);

  const resetAll = () => {
    dispatch(resetBulkExcludeMailboxState());
    setErrorMessage('');
    form.reset();
  };

  const handleClose = () => {
    resetAll();
    closeModal();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    form.validate();
  };

  const handleFileUpload = (e) => {
    handleOnFileUpload(e, (emails) => form.change('emails', emails.join(',')));
  };

  useEffect(() => {
    if (bulkExcludeMailboxState.statusCode === 200) {
      if (bulkExcludeMailboxState.serviceType) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': bulkExcludeMailboxState.serviceType,
        });
        dispatch(
          showFixedAlert({
            descriptionHtml: t(
              'organization_list_page.bulk_exclude_mailbox.success_submitted_with_link',
              {
                link,
              }
            ),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('organization_list_page.bulk_exclude_mailbox.success_submitted'),
          })
        );
      }
      resetAll();
      handleClose();
    } else if (bulkExcludeMailboxState.error?.data?.message) {
      dispatch(resetBulkExcludeMailboxState());
      form.reset(true);
      setErrorMessage(bulkExcludeMailboxState.error?.data?.message);
    }
  }, [bulkExcludeMailboxState.statusCode]);

  return {
    bulkExcludeMailboxState,
    errorMessage,
    form,
    credentialTypeOptions,
    handleSubmit,
    handleClose,
    handleFileUpload,
  };
}
