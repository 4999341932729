import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { organizationInformationPath, resellersListPath } from '@config/Routes/WebClientRoutes';
import {
  setOrganizationDetailState,
  setPartnerDetailsState,
} from '@features/application/applicationSlice';
import { fetchMigrateStatusListAsync } from '@features/migrateStatus/migrateStatusInfoSlice';
import { MIGRATE_STATUS_COLUMNS } from './constants/migrateStatusConstants';

export default function useMigrateStatusFetching(props) {
  const { defaultFilter, setFilterState, defaultPagination, setPaginationState } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [isReset, setIsReset] = useState(false);
  const [showMesageDialog, setShowMesageDialog] = useState(false);
  const [messageDialog, setMesageDialog] = useState({ title: '', message: '' });

  const handleFetchMigrateStatusListAsync = (
    pageIndex,
    pageSize,
    resellerId,
    organizationOwnerId,
    filters
  ) => {
    dispatch(
      fetchMigrateStatusListAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageNumber: pageIndex,
        pageSize,
        resellerId,
        organizationOwnerId,
        filters,
      })
    );
  };

  const handleResetFilter = () => {
    setIsReset(true);
  };

  useEffect(() => {
    if (isReset) {
      setFilterState(defaultFilter);
      setPaginationState(defaultPagination);
    }
    setIsReset(false);
  }, [isReset]);

  const tableColumns = () => {
    // add click able function
    return MIGRATE_STATUS_COLUMNS.map((item) => {
      if (item.columnName === 'organizationName') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleResetFilter();
          dispatch(
            setOrganizationDetailState({
              organizationInformationPath,
              row: { organizationOwnerId: row.organizationOwnerId, orgName: row.organizationName },
            })
          );
          return navigate({
            pathname: organizationInformationPath,
            search: createSearchParams({
              id: row.organizationId,
              name: row.organizationName,
            }).toString(),
          });
        };
      }
      if (item.columnName === 'partnerName') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleResetFilter();
          dispatch(
            setPartnerDetailsState({
              resellersListPath,
              row: { id: row.partnerId },
            })
          );
          return navigate({
            pathname: resellersListPath,
            search: createSearchParams({
              id: row.partnerId,
              name: row.partnerName,
            }).toString(),
          });
        };
      }
      if (
        item.columnName === 'errorMessage' ||
        item.columnName === 'queryParams' ||
        item.columnName === 'additionalData' ||
        item.columnName === 'testMessage' ||
        item.columnName === 'messageIds'
      ) {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          setShowMesageDialog(true);
          setMesageDialog({ title: item.label, message: row[item.columnName] });
          return '';
        };
      }

      return item;
    });
  };

  return {
    handleFetchMigrateStatusListAsync,
    tableColumns,
    showMesageDialog,
    setShowMesageDialog,
    messageDialog,
  };
}
