import { editResellerImpersonateConfigsAPI } from '@services/WebApiService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const editResellerImpersonateConfigsAsync = createAsyncThunk(
  'EditResellerImpersonateConfigs/editResellerImpersonateConfigs',
  async (paramsList) => {
    try {
      const data = await editResellerImpersonateConfigsAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = editResellerImpersonateConfigsAsync;

const editResellerImpersonateConfigsExtraReducers = {
  [pending]: (state) => {
    state.editResellerImpersonateConfigsState.fetching = true;
  },
  [rejected]: (state) => {
    state.editResellerImpersonateConfigsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.editResellerImpersonateConfigsState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.editResellerImpersonateConfigsState.statusCode = action?.payload?.status;
      state.editResellerImpersonateConfigsState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.editResellerImpersonateConfigsState.message =
        'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.editResellerImpersonateConfigsState.message =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.editResellerImpersonateConfigsState.statusCode = action?.payload?.error?.status;
      state.editResellerImpersonateConfigsState.message =
        statusMessage?.title || statusMessage?.message;
      state.editResellerImpersonateConfigsState.error = action.payload.error;
    } else {
      state.editResellerImpersonateConfigsState.success = true;
      state.editResellerImpersonateConfigsState.serviceType =
        action.payload.data.data.serviceTypeCode;
    }
  },
};

export { editResellerImpersonateConfigsAsync, editResellerImpersonateConfigsExtraReducers };
