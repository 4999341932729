import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useUrlQuery() {
  const location = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => {
    const obj = {};
    const paramsString = location.search.startsWith('?')
      ? location.search.substring(1)
      : location.search;
    const searchParams = new URLSearchParams(paramsString);
    searchParams.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }, [location.search]);

  const setQuery = useCallback(
    (value) => {
      if (typeof value === 'object') {
        const search = Object.keys(value)
          .map((key) => (value[key] || value[key] === 0 ? `${key}=${value[key]}` : ''))
          .filter((s) => !!s)
          .join('&');
        location.search = search;
        navigate(location);
      } else {
        location.search = value?.toString() ?? '';
        navigate(location);
      }
    },
    [location, navigate]
  );

  return { query, setQuery };
}
