import { ITBox, QboAdvanceTable, QboCard, QboTypography } from '@ui/Components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { getBulkExcludeMailboxItemsAsync } from '@features/bulkExcludeMailbox/getBulkExcludeMailboxItemsSlice';
import { BULK_EXCLUDE_MAILBOX_ITEMS_HEADERS } from './constants/toolsRequestHistoryConstants';

const initialPagination = { pageIndex: 0, pageSize: 10 };

export default function BulkExcludeMailboxItems({ jobId }) {
  const { t } = useTranslation();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [pagination, setPagination] = useState(initialPagination);
  const dispatch = useDispatch();
  const { getBulkExcludeMailboxItemsState } = useSelector(
    (state) => state.getBulkExcludeMailboxItems
  );

  useEffect(() => {
    dispatch(
      getBulkExcludeMailboxItemsAsync({
        accessToken,
        reset: true,
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        id: jobId,
      })
    );
  }, [pagination, jobId]);

  return (
    <QboCard>
      <ITBox className="SystemStatusSection__wrapper" direction="row">
        <QboTypography style={{ margin: 16, fontSize: '2rem' }}>
          {t('tools_request_status.bulk_exclude_mailbox.title', { jobId })}
        </QboTypography>
      </ITBox>

      <QboAdvanceTable
        isLoading={getBulkExcludeMailboxItemsState.fetching}
        enableTopToolBar={false}
        headers={BULK_EXCLUDE_MAILBOX_ITEMS_HEADERS}
        rows={getBulkExcludeMailboxItemsState.list}
        pagination={pagination}
        setPagination={setPagination}
        rowsCount={getBulkExcludeMailboxItemsState.pagination?.TotalCount}
      />
    </QboCard>
  );
}

BulkExcludeMailboxItems.propTypes = {
  jobId: PropTypes.number,
};

BulkExcludeMailboxItems.defaultProps = {
  jobId: null,
};
