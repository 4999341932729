import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { QboButton } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import useFeatureFlag from '@hooks/useFeatureFlag';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import { useDispatch } from 'react-redux';
import { showFixedAlert } from '@features/application/applicationSlice';

const msGroupLabels = [
  {
    label: 'Backup Type',
    properties: 'backup_type',
    valType: 'string',
  },
  {
    label: 'Calendar',
    properties: 'calendar',
    valType: 'bool',
    editable: true,
  },
  {
    label: 'File',
    properties: 'file',
    valType: 'bool',
    editable: true,
  },
  {
    label: 'Site',
    properties: 'site',
    valType: 'bool',
    editable: true,
  },
  {
    label: 'Note',
    properties: 'note',
    valType: 'bool',
    editable: true,
  },
  {
    label: 'Team',
    properties: 'team',
    valType: 'bool',
    editable: true,
  },
  {
    label: 'Shared Mailbox',
    properties: 'shared_mailbox',
    valType: 'bool',
    editable: true,
  },
  {
    label: 'Task',
    properties: 'task',
    valType: 'bool',
    editable: true,
  },
];

const initialEditState = {
  isEditting: false,
  originalValues: {},
  fieldValues: {},
};

export default function ResellerMsGroupSettingsSection(props) {
  const { data, onSave } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { featureFlags, hasPermission } = useFeatureFlag();
  const [editState, setEditState] = useState(initialEditState);

  const flattenedData = useMemo(() => {
    return {
      backup_type: data.backup_type,
      ...data.products,
    };
  }, [data]);

  const changedValues = useMemo(() => {
    if (!editState.originalValues || !editState.fieldValues) {
      return editState.fieldValues ?? {};
    }
    const retval = {};
    Object.keys(editState.originalValues).forEach((k) => {
      if (editState.originalValues[k] === editState.fieldValues[k]) return;
      retval[k] = editState.fieldValues[k] ?? null;
    });
    return retval;
  }, [editState]);

  const isSaveable = changedValues && Object.keys(changedValues).length > 0;

  const handleInitializeEdit = () => {
    setEditState({
      fieldValues: flattenedData,
      originalValues: flattenedData,
      isEditting: true,
    });
  };

  const handleCancelEdit = () => {
    setEditState(initialEditState);
  };

  const handleUpdateField = (fieldName, value) => {
    setEditState({
      ...editState,
      fieldValues: {
        ...editState.fieldValues,
        [fieldName]: value,
      },
    });
  };

  const handleSave = () => {
    if (!isSaveable) {
      dispatch(
        showFixedAlert({
          descriptionHtml: t('partner_list_page.edit_reseller_configs.validations.no_changes'),
          severity: 'error',
        })
      );
      return;
    }
    const preview = [];
    const msGroupSettings = { ...data };

    Object.keys(changedValues).forEach((k) => {
      const label = msGroupLabels.find((l) => l.properties === k);
      preview.push({
        label: label.label,
        subHeadingText: label.subHeadingText,
        key: k,
        value: changedValues[k],
      });

      if (k === 'backup_type') {
        msGroupSettings.backup_type = changedValues[k];
      } else {
        msGroupSettings.products[k] = changedValues[k];
      }
    });

    onSave({
      preview,
      msGroupSettings,
    });
  };

  return (
    <>
      {hasPermission(featureFlags.EditResellerSettings) && (
        <div className="ResellersPage__settings-toolbar">
          {editState.isEditting ? (
            <>
              <Button
                className="ResellersPage__cancel-button"
                onClick={() => handleCancelEdit('resellerDetails')}>
                Cancel
              </Button>
              <QboButton
                variant="contained"
                disabled={!isSaveable}
                withTitle={!isSaveable}
                title={
                  !isSaveable
                    ? t('partner_list_page.edit_reseller_configs.validations.no_changes')
                    : null
                }
                onClick={handleSave}>
                Save
              </QboButton>
            </>
          ) : (
            <QboButton variant="contained" onClick={handleInitializeEdit}>
              Edit
            </QboButton>
          )}
        </div>
      )}
      {msGroupLabels?.map((label) => {
        return editState.isEditting && label.editable ? (
          <QboCardSingleList
            key={label.label}
            subHeadingText={label?.subHeadingText}
            leftContent={label.label}
            editable
            value={editState.fieldValues[label.properties]}
            valueType={label.valType}
            onChange={(v) => handleUpdateField(label.properties, v)}
            options={label.options}
          />
        ) : (
          <QboCardSingleList
            key={label.label}
            subHeadingText={label?.subHeadingText}
            leftContent={label.label}
            rightContent={flattenedData[label.properties].toString()}
          />
        );
      })}
    </>
  );
}

ResellerMsGroupSettingsSection.propTypes = {
  data: PropTypes.object,
  onSave: PropTypes.func,
};

ResellerMsGroupSettingsSection.defaultProps = {
  data: {},
  onSave: () => {},
};
