import { createSlice } from '@reduxjs/toolkit';
import {
  getBulkExcludeMailboxItemsAsync,
  getBulkExcludeMailboxItemsExtraReducers,
} from './serviceActions/getBulkExcludeMailboxItemsAsync';

const initialState = {
  getBulkExcludeMailboxItemsState: {
    fetching: false,
    statusCode: null,
    list: [],
    errorMessage: null,
    pagination: {},
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const getBulkExcludeMailboxItemsSlice = createSlice({
  name: 'getBulkExcludeMailboxItems',
  initialState,
  reducers: {
    resetGetBulkExcludeMailboxItemsState(state) {
      state.getBulkExcludeMailboxItemsState = initialState.getBulkExcludeMailboxItemsState;
    },
  },
  extraReducers: {
    ...getBulkExcludeMailboxItemsExtraReducers,
  },
});

export { getBulkExcludeMailboxItemsAsync };

export const { resetGetBulkExcludeMailboxItemsState } = getBulkExcludeMailboxItemsSlice.actions;
export default getBulkExcludeMailboxItemsSlice.reducer;
