export default function useDataService() {
  const subscriptionHistoryHeaders = [
    { id: 1, label: 'ID', columnName: 'subscriptionId' },
    { id: 1, label: 'Status', columnName: 'status' },
    { id: 1, label: 'Seat/Storage/Company', columnName: 'subscriptionSeats' },
    { id: 1, label: 'Plan Name', columnName: 'planName' },
    { id: 1, label: 'Plan Category', columnName: 'productCategory' },
    { id: 1, label: 'Basic Price', columnName: 'basicPrice' },
    { id: 1, label: 'End User Price', columnName: 'endUserPrice' },
    { id: 1, label: 'Currency', columnName: 'planCurrency' },
    { id: 1, label: 'Subscribed At', columnName: 'subscribedOn' },
    { id: 1, label: 'Unsubscribed At', columnName: 'unsubscribedOn' },
    { id: 1, label: 'Unsubscribed Reason', columnName: 'unsubscribedReason' },
  ];
  return {
    subscriptionHistoryHeaders,
  };
}
