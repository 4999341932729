import {
  numberOfOrganizationListPath,
  numberOfSubResellersListPath,
  numberOfUsersListPath,
  resellersListPath,
  showUsersListPath,
} from '@config/Routes/WebClientRoutes';
import { createSearchParams } from 'react-router-dom';

export default function useDataService() {
  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const TFAStatusItemList = [
    {
      id: 1,
      value: 'Enabled',
      title: 'Enabled',
    },
    {
      id: 2,
      value: 'Disabled',
      title: 'Disabled',
    },
  ];

  const roleItemList = [
    {
      id: 1,
      value: 'admin',
      title: 'Admin',
    },
    {
      id: 2,
      value: 'dme_admin',
      title: 'DME Admin',
    },
    {
      id: 3,
      value: 'finance',
      title: 'Finance',
    },
    {
      id: 4,
      value: 'reseller_owner',
      title: 'Reseller Owner',
    },
    {
      id: 5,
      value: 'super_admin',
      title: 'Super Admin',
    },
    {
      id: 6,
      value: 'support',
      title: 'Support',
    },
  ];
  const licenseTypeItemList = [
    {
      id: 1,
      value: 'Paid',
      title: 'Paid',
    },
    {
      id: 2,
      value: 'NFR',
      title: 'NFR',
    },
    {
      id: 3,
      value: 'NFP',
      title: 'NFP',
    },
  ];

  const columnNames = [
    {
      id: 1,
      value: 'ID',
      title: 'Partner ID',
    },
    {
      id: 2,
      value: 'Name',
      title: 'Name',
    },
    {
      id: 3,
      value: 'Email',
      title: 'Email',
    },
    {
      id: 4,
      value: 'Role',
      title: 'Role',
    },
    {
      id: 5,
      value: 'CreatedOn',
      title: 'Created On',
    },
  ];

  const clickableCoulmnList = [
    {
      id: 1,
      column: 'partnerName',
      path: (row) => {
        const queryParams = createSearchParams({ id: row?.id, name: row?.partnerName }).toString();
        return [resellersListPath, queryParams];
      },
    },
  ];

  const countryItemList = [
    'AD',
    'AE',
    'AF',
    'AI',
    'AL',
    'AO',
    'AR',
    'AS',
    'AT',
    'AU',
    'AW',
    'AX',
    'AZ',
    'BB',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BJ',
    'BM',
    'BN',
    'BO',
    'BR',
    'BS',
    'BT',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CD',
    'CF',
    'CG',
    'CH',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CU',
    'CV',
    'CW',
    'CY',
    'CZ',
    'DE',
    'DJ',
    'DK',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EG',
    'EH',
    'ER',
    'ES',
    'ET',
    'FI',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'GA',
    'GB',
    'GD',
    'GE',
    'GF',
    'GG',
    'GH',
    'GI',
    'GL',
    'GM',
    'GN',
    'GP',
    'GQ',
    'GR',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HN',
    'HR',
    'HT',
    'HU',
    'ID',
    'IE',
    'IL',
    'IM',
    'IN',
    'IO',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JE',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LK',
    'LR',
    'LS',
    'LT',
    'LU',
    'LV',
    'LY',
    'MA',
    'MC',
    'MD',
    'ME',
    'MF',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MQ',
    'MR',
    'MS',
    'MT',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NF',
    'NG',
    'NI',
    'NL',
    'NO',
    'NP',
    'NR',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PG',
    'PH',
    'PK',
    'PL',
    'PM',
    'PN',
    'PR',
    'PS',
    'PT',
    'PW',
    'PY',
    'QA',
    'RE',
    'RO',
    'RS',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SG',
    'SH',
    'SI',
    'SJ',
    'SK',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'SS',
    'ST',
    'SV',
    'SX',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TF',
    'TG',
    'TH',
    'TJ',
    'TK',
    'TL',
    'TM',
    'TN',
    'TO',
    'TR',
    'TT',
    'TV',
    'TW',
    'TZ',
    'UA',
    'UG',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WF',
    'WS',
    'YE',
    'YT',
    'ZA',
    'ZM',
    'ZW',
  ];

  return {
    buttonStyle,
    TFAStatusItemList,
    roleItemList,
    licenseTypeItemList,
    countryItemList,
    columnNames,
    clickableCoulmnList,
  };
}
