import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMigrateStatusListAsync,
  getMigrateStatusListExtraReducers,
} from './serviceActions/fetchMigrateStatusListAsync';

const initialState = {
  migrateStatusListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginations: {},
    migrateStatusList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    migrateStatusListCorsErrorMessage: null,
  },
};

const migrateStatusSlice = createSlice({
  name: 'migrateStatusList',
  initialState,
  reducers: {
    resetMigrateStatusListState(state) {
      state.migrateStatusListState = initialState.migrateStatusListState;
    },
  },
  extraReducers: {
    ...getMigrateStatusListExtraReducers,
  },
});

export { fetchMigrateStatusListAsync };
export const { resetMigrateStatusListState } = migrateStatusSlice.actions;
export default migrateStatusSlice.reducer;
