import { QboCard, QboNoStatus, QboSimpleDataTable } from '@ui/Components';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import PropTypes from 'prop-types';
import React from 'react';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSubscriptionHistoryService from './pageHooks/subscriptionHistoryService';
import useDataService from './pageHooks/useDataService';

export default function SubscripitionHistoryPage({ organizationOwnerId }) {
  const { t } = useTranslation();
  const { getSubscriptionHistoryListState } = useSubscriptionHistoryService(organizationOwnerId);
  const { subscriptionHistoryHeaders } = useDataService();

  const { fetching, subscriptionHistoryList } = getSubscriptionHistoryListState;

  if (!subscriptionHistoryList) {
    return (
      <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
        <QboNoStatus title={t('subscription_history.no_result')} />
      </QboCard>
    );
  }

  return (
    <QboCard
      className="QboCard__wrapper_search_text_highlight"
      sx={{
        scrollbarWidth: 'none !important',
        overflowY: 'scroll !important',
        height: '65vh',
      }}>
      <QboSimpleDataTable
        rows={subscriptionHistoryList}
        header={subscriptionHistoryHeaders}
        loading={fetching}
      />
    </QboCard>
  );
}

SubscripitionHistoryPage.propTypes = {
  organizationOwnerId: PropTypes.number,
};

SubscripitionHistoryPage.defaultProps = {
  organizationOwnerId: null,
};
